import { DataAccessService } from "../core/api";

export default async function getAllowedOrgs() {
  try {
    const client = new DataAccessService();
    //const response = await client.get(`/api/orgs`);
    await new Promise((e) => setTimeout(e, 1e3));
    return [
      {
        org: "amazon-archives",
        orgType: "OpenSource",
      },
      {
        org: "amznlabs",
        orgType: "OpenSource",
      },
      {
        org: "eksctl-io",
        orgType: "StrategicProject",
      },
      {
        org: "corretto",
        orgType: "StrategicProject",
      },
      {
        org: "aws-geospatial",
        orgType: "OpenSource",
      },
    ];
  } catch (err) {
    console.warn(err);
    throw err;
  }
}
