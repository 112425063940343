
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react';
import { getMatchesCountText } from "../../helpers/getMatchesCountText";
import { InternalLink } from "../internal-link";

export const InheritedReposTable = ({ repos, loading, refresh }) => {
    // List of table ids, also used for defining which attribute on item to sort and filter
    const url = "url";
    const owners = "owners";
    const rules = "rules";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        repos,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [url, owners],
            },
            sorting: {},
            selection: {}
        }
    );

    return (<>
        <Table
            {...collectionProps}
            columnDefinitions={[
                {
                    id: url,
                    header: "Repository",
                    cell: item => <InternalLink href={`${item.id}`}>{item.url}</InternalLink>,
                    isRowHeader: true,
                    sortingField: url
                },
                {
                    id: owners,
                    header: "Repository owners",
                    cell: item => item.owners?.map(owner => owner.fullName).join(', '),
                    sortingField: owners
                },
                {
                    id: rules,
                    header: "Rules",
                    cell: item => item.rules.length,
                    sortingField: rules,
                }
            ]}
            items={items}
            loading={loading}
            loadingText="Loading inherited repositories..."
            empty="No Inherited Repos Found"
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search repositories"
                />
            }
            header={
                < Header
                    counter={`(${repos.length})`}
                    description="Inherited repositories are passed down from higher-level management to your team, or you are added as an additional contributor to the repository. You cannot make changes."
                >
                    Inherited repositories
                </Header >
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="refresh" variant="icon" disabled={loading} onClick={refresh} />
                        < Pagination
                            {...paginationProps}
                            disabled={loading}

                        />
                    </SpaceBetween>
                </Box>
            }
        />
    </>);
}