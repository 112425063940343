import { User } from '../core/user';
import { isAvpFlagOn } from "./isAvpFlagOn";

const ADMIN_ROLE = 'Admin';

export const isAdmin = () => {
    const user = new User();
    const avpFlagOn = isAvpFlagOn();
    if (avpFlagOn) {
        return user.is_manager;
    } else {
        return user.roles.some(role => role === ADMIN_ROLE);
    }
}