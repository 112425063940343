import { Icon, Popover, SpaceBetween } from "@amzn/awsui-components-react";
import { RuleEnforcementType } from "../../core/constants";
import { User } from "../../core/user";

export const getRenderedEnforcementType = (enforcementType) => {
    if (enforcementType === RuleEnforcementType.DISABLED) {
        return <p style={{ color: 'gray' }}><Icon name={"status-positive"} /> None</p>
    } else if (enforcementType === RuleEnforcementType.BLOCKING) {
        return <p style={{ color: '#fe5e64' }}><Icon name={"status-negative"} /> Blocking</p>
    } else if (enforcementType === RuleEnforcementType.WARNING) {
        return <p style={{ color: '#e0ca57' }}><Icon name={"status-warning"} /> Warning</p>
    }
}

export const getEnforcementTypeSelectOptions = () => {
    return [
        { label: getRenderedEnforcementType(RuleEnforcementType.DISABLED), value: RuleEnforcementType.DISABLED },
        { label: getRenderedEnforcementType(RuleEnforcementType.BLOCKING), value: RuleEnforcementType.BLOCKING },
        { label: getRenderedEnforcementType(RuleEnforcementType.WARNING), value: RuleEnforcementType.WARNING }
    ]
}

export const getRenderedName = (rule) => {
    return (
        <Popover
            dismissButton={false}
            position="top"
            size="large"
            content={getRulePopoverContent(rule)}
        >
            {rule.name}
        </Popover>
    );
}

const getRulePopoverContent = (rule) => {
    switch (rule.tool) {
        case 'regex':
            return (<SpaceBetween size="s">
                <div>{rule.description}</div>
                <div>
                    <small>Regex Pattern:</small>
                    <br />
                    <code>
                        {rule.config}
                    </code>
                </div>
            </SpaceBetween>);
        default:
            return rule.config;
    }
}

export const isRepoOwner = (ruleConfig) => {
    const user = new User();
    return ruleConfig?.owners?.some(owner => owner.username  === user.userId);
}