import { LocalStorage } from "../core/storage";
import { FEATURE_FLAGS } from "../core/user/user";

export const isTopRopeFeatureOn = () => {
    let topRopeFeatureEnabled = false;

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        topRopeFeatureEnabled = featureFlags['top_rope'];
    }

    return topRopeFeatureEnabled;
}