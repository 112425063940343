// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Alert,
    Box,
    Button,
    CopyToClipboard,
    Header,
    Pagination,
    Popover,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { getMatchesCountText } from '../../helpers/getMatchesCountText';
import daysAgo from "../../helpers/daysAgo";
import { ResolutionModal } from "./ResolutionModal";

export const NewBypassesTable = ({ bypasses, loading, refresh }) => {
    // State
    const [selectedItems, setSelectedItems] = useState([]);
    const [isResolutionModalVisible, setIsResolutionModalVisible] = useState(false);

    // List of table ids, also used for defining which attribute on item to sort and filter
    const directory = "directory";
    const commitId = "commitId";
    const time = "time";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        bypasses,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [directory, commitId],
            },
            sorting: {
                defaultState: {
                    sortingColumn: {
                        sortingField: time
                    },
                    isDescending: true
                }
            },
            selection: {}
        }
    );

    return (<>
        <ResolutionModal
            isVisible={isResolutionModalVisible}
            setIsVisible={setIsResolutionModalVisible}
            selectedItems={selectedItems}
            refreshBypasses={() => {
                refresh();
                setSelectedItems([]);
            }}
        />
        <Table
            {...collectionProps}
            header={
                <SpaceBetween
                    direction="vertical"
                    size="xs"
                >
                    <Header
                        counter={selectedItems.length ? `(${selectedItems.length}/${bypasses.length})` : `(${bypasses.length})`}
                        actions={
                            <Button data-testid={'submit-reason-button'}
                                variant='primary'
                                disabled={selectedItems.length === 0}
                                onClick={() => setIsResolutionModalVisible(true)}
                            >
                                Submit reason
                            </Button>
                        }

                    >
                        Unacknowledged bypasses
                    </Header>

                    <Alert
                        statusIconAriaLabel="Warning"
                        type="warning"
                        header="[Action Required] Submit reasons for all unacknowledged bypasses."
                    >
                        If you don't submit a reason after 30 days, it will be flagged as a potential compliance issue and escalate to your manager.
                    </Alert>
                </SpaceBetween>
            }
            selectionType='multi'
            onSelectionChange={({ detail }) => {
                setSelectedItems(detail.selectedItems);
            }}
            selectedItems={selectedItems}
            items={items}
            loading={loading}
            loadingText='Loading unacknowledged bypasses...'
            columnDefinitions={
                [
                    {
                        id: directory,
                        header: "Directory",
                        cell: item => item.directory,
                        sortingField: directory
                    },
                    {
                        id: commitId,
                        header: "Commit ID",
                        cell: item => {
                            return item.commitId ? <CopyToClipboard
                                copyButtonAriaLabel="Copy Commit ID"
                                copyErrorText="Commit ID failed to copy"
                                copySuccessText="Commit ID copied"
                                textToCopy={item.commitId}
                                variant="inline"
                            />
                                :
                                <>-</>
                        },
                        sortingField: commitId
                    },
                    {
                        id: time,
                        header: "Commit date",
                        cell: item => <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            content={new Date(item.time).toDateString()}
                        >
                            {daysAgo(item.time)}
                        </Popover>,
                        sortingField: time
                    }
                ]
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search by commit ID or repository directory"
                />
            }
            pagination={
                <Box float="right">
                    < Pagination
                        {...paginationProps}
                        disabled={loading}

                    />
                </Box>
            }
            empty="No unacknowledged bypasses found 🎉"

        />
    </>);
}
