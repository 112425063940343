import React from "react";

import { Box, Grid } from "@amzn/awsui-components-react";

/**
 * CustomHomeHeader
 *
 * Custom home header whose text can be configured via the following properties: title, supertitle, and subtitle.
 * If this component is used in conjunction with the Polaris Flashbar component, configure the flashBarDisplayed
 * boolean. This property will properly adjust the component's padding.
 *
 * @param title
 * @param supertitle
 * @param subtitle
 * @param flashBarDisplayed
 * @returns {JSX.Element}
 * @constructor
 */
export const CustomHomeHeader = ({
    title="",
    supertitle="",
    subtitle="",
    flashBarDisplayed=false
}) => {
    return (
        <div>
            <Box className="custom-home__header">
                <Grid gridDefinition={[{ "colspan": 6, "offset": 2 }]}>
                    <div
                        data-testid={"custom-header"}
                        className="custom-home__header-title"
                        style={flashBarDisplayed ? null : { paddingTop: '68px'}}
                    >
                        <Box
                            data-testid={"supertitle"}
                            color="inherit"
                            fontWeight="normal"
                            margin={{ bottom: "xs" }}
                            padding={{ bottom: "xs" }}
                        >
                            {supertitle}
                        </Box>
                        <div className="custom-home__header-title">
                            <Box
                                fontSize="display-l"
                                padding={{top: "n", bottom: "s"}}
                                fontWeight="bold"
                                color={"inherit"}
                            >
                                <div data-testid={"title"} className="custom-home__header-title">
                                    <strong>{title}</strong>
                                </div>
                            </Box>
                            <Box
                                data-testid={"subtitle"}
                                fontSize="heading-xl"
                                color="inherit"
                                fontWeight="light"
                            >
                                {subtitle}
                            </Box>
                        </div>
                    </div>
                </Grid>
            </Box>
            <div>
                <br />
            </div>
        </div>
    );
};
