import React from "react";
import {
    Icon
  } from '@amzn/awsui-components-react';

export function negativeIconBuilder(value) {
    return <div><Icon
      name="status-negative"
      size="normal"
      variant="error"
    /> {value}</div>
}

export  function positiveIconBuilder(value) {
    return <div><Icon
      name="status-positive"
      size="normal"
      variant="success"
    /> {value} </div>
}

export const LeaveIcon = (props) => (
    <Icon
        svg={<span><svg height={props.style.height} width={props.style.width} viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8186 12.647L12.0605 11.8341C12.2615 11.4825 12.4237 11.0666 12.5542 10.5864C12.6846 10.1062 12.7481 9.59583 12.7481 9.05517C12.7481 7.47855 12.2332 6.14012 11.2035 5.03989C10.1738 3.93588 8.92544 3.38387 7.45844 3.38387C6.96474 3.38387 6.49219 3.45193 6.04081 3.58048C5.58942 3.71281 5.19798 3.89429 4.8665 4.12114L4.09068 3.28935C4.58438 2.94907 5.12393 2.69198 5.70227 2.51427C6.28413 2.33657 6.86952 2.24961 7.45844 2.24961C8.25894 2.24961 9.00302 2.39329 9.69068 2.67307C10.3783 2.95664 10.9919 3.34985 11.5315 3.8527L12.4307 2.88858L13.1713 3.68256L12.272 4.64668C12.801 5.26296 13.1889 5.9473 13.4358 6.69969C13.6826 7.45208 13.806 8.23472 13.806 9.05517C13.806 9.68657 13.7249 10.3066 13.5698 10.9154C13.4111 11.5279 13.1607 12.1025 12.8222 12.647H12.8186ZM7.98741 7.46721V5.2365H6.92947V6.33295L7.98741 7.46721ZM13.2418 16.3333L11.4081 14.3673C10.8438 14.8475 10.2338 15.2142 9.58489 15.4713C8.93249 15.7284 8.22368 15.8569 7.45844 15.8607C6.58741 15.8607 5.76927 15.683 4.99698 15.3238C4.22821 14.9647 3.55466 14.4769 2.97985 13.8569C2.40504 13.2406 1.9466 12.5184 1.61159 11.6942C1.27657 10.87 1.1073 9.98904 1.1073 9.05517C1.1073 8.28765 1.21662 7.55417 1.43526 6.86227C1.6539 6.17037 2.00655 5.48981 2.50378 4.8206L0 2.13619L0.758186 1.3233L14 15.5204L13.2418 16.3333ZM7.45844 14.7265C8.06851 14.7265 8.65038 14.6244 9.19345 14.424C9.74005 14.2236 10.2443 13.9249 10.7028 13.5355L3.2796 5.57677C2.91637 6.05694 2.63778 6.59761 2.45088 7.20255C2.26398 7.80748 2.16877 8.42377 2.16877 9.05517C2.16877 10.6318 2.68363 11.9702 3.71335 13.0704C4.74307 14.1707 5.99144 14.7265 7.46196 14.7265H7.45844ZM5.34257 1.13426V0H9.57431V1.13426H5.34257Z" fill={props.style.fill}/>
</svg></span>}
    />)