import { User } from "../../core/user";

const parseDelegateRole = (role) => {
    return JSON.parse(role.replace(/^Delegate/, ''));
}

export const getDelegationsFromRoles = () => {
    return (new User()).roles
        .filter(role =>
            role.startsWith("Delegate{")
        )
        .map(delegation => {
            return parseDelegateRole(delegation);
        })
}