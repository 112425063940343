
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react';
import { getMatchesCountText } from "../../helpers/getMatchesCountText";
import { getRenderedEnforcementType, getRenderedName } from "../../helpers/rules/utils";

export const InheritedRulesTable = ({ rules, loading, refresh }) => {
    // List of table ids, also used for defining which attribute on item to sort and filter
    const name = "name";
    const description = "description";
    const categories = "categories";
    const commitEnforcement = "commitEnforcement";
    const pushEnforcement = "pushEnforcement";
    const inheritedFrom = "inheritedFrom";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        rules,
        {
            pagination: {
                pageSize: 20,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [name, description, categories],
            },
            sorting: {},
            selection: {}
        }
    );

    return (<>
        <Table
            {...collectionProps}
            columnDefinitions={[
                {
                    id: name,
                    header: "Rule name",
                    cell: item => getRenderedName(item),
                    isRowHeader: true,
                    sortingField: name
                },
                {
                    id: categories,
                    header: "Categories",
                    cell: item => item.categories.join(', '),
                    sortingField: categories
                },
                {
                    id: inheritedFrom,
                    header: "Inherited from",
                    cell: item => item.manager ? item.manager.fullName || item.manager.username : '',
                    sortingField: "manager"
                },
                {
                    id: commitEnforcement,
                    header: "Commit enforcement",
                    cell: item => getRenderedEnforcementType(item.precommit),
                    sortingField: 'precommit'
                },
                {
                    id: pushEnforcement,
                    header: "Push enforcement",
                    cell: item => getRenderedEnforcementType(item.prepush),
                    sortingField: 'prepush'
                }
            ]}
            items={items}
            loading={loading}
            loadingText="Loading inherited rules..."
            empty="No Inherited Rules Found"
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search rules"
                />
            }
            header={
                < Header
                    counter={`(${rules.length})`}
                    description="Inherited rules are passed down from higher-level management to your team, you cannot make changes."
                >
                    Inherited rules
                </Header >
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="refresh" variant="icon" disabled={loading} onClick={refresh} />
                        < Pagination
                            {...paginationProps}
                            disabled={loading}
                        />
                    </SpaceBetween>
                </Box>
            }
        />
    </>);
}