import { Alert, BreadcrumbGroup, ColumnLayout, ExpandableSection, SpaceBetween, Wizard } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RuleEnforcementType } from "../../../core/constants";
import { getRenderedEnforcementType } from "../../../helpers/rules/utils";
import { RepoInfoForm } from "./RepoInfoForm";
import { FlashbarPublisher } from "../../FlashbarPublisher";
import { RulePicker } from "../../team-rules-ui/add-ui/RulePicker";
import { EnforcementPicker } from "../../team-rules-ui/add-ui/EnforcementPicker";
import { User } from "../../../core/user";

export const AddRepoRules = () => {
    const AdditionalContributorsSelection = {
        SELECTED: 'selected',
        NOT_SELECTED: 'not_selected'
    };
    const user = new User();
    const [repoUrl, setRepoUrl] = useState("");
    const [urlErrorText, setUrlErrorText] = useState("");
    const [ownersErrorText, setOwnersErrorText] = useState("");
    const [additionalContributorsErrorText, setAdditionalContributorsErrorText] = useState("");
    const [additionalContributors, setAdditionalContributors] = useState([]);
    const [owners, setOwners] = useState([user.userId]);
    const [additionalContributorsSelection, setAdditionalContributorsSelection] = useState(AdditionalContributorsSelection.NOT_SELECTED);
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [selectedRules, setSelectedRules] = useState([]);
    const [ruleEnforcements, setRuleEnforcements] = useState({});
    const [ruleSelectionErrorText, setRuleSelectionErrorText] = useState("");
    const [repoInfoErrorText, setRepoInfoErrorText] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const navigate = useNavigate();

    const defaultEnforcement = {
        commitEnforcement: RuleEnforcementType.WARNING,
        pushEnforcement: RuleEnforcementType.BLOCKING,
    }

    const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;

    const validateRepoFormFields = () => {
        // Removes whitespace from both sides of a string
        const url = repoUrl.trim();

        let hasErrors = false;
        if (!urlRegex.test(url)) {
            setUrlErrorText("You have entered an invalid repo URL. To proceed, please enter a valid repo URL. Example: https://github.com/aws/aws-cli");
            hasErrors = true;
        }
        if (url === "") {
            setUrlErrorText("Url cannot be empty.");
            hasErrors = true;
        }
        if (owners.length === 0) {
            setOwnersErrorText("Please add at least one owner.");
            hasErrors = true;
        } else if (owners.some(owner => owner === "")) {
            setOwnersErrorText("Owner alias cannot be empty. Please add an alias or remove the entry.");
            hasErrors = true;
        }
        if (additionalContributors.some(contributor => contributor === "")) {
            setAdditionalContributorsErrorText("Contributor alias cannot be empty.")
            hasErrors = true;
        }
        return hasErrors;
    }

    useEffect(() => {
        selectedRules.forEach(rule => {
            if (!ruleEnforcements[rule.id]) {
                setRuleEnforcements({ [rule.id]: defaultEnforcement, ...ruleEnforcements });
            }
        });
        setRuleSelectionErrorText(null);
    }, [selectedRules]);

    const handleCancel = () => {
        window.location.hash = "";
    }

    const handleNavigate = ({ detail }) => {
        if (detail.reason === 'next') {
            switch (detail.requestedStepIndex) {
                case 1:
                    const hasErrors = validateRepoFormFields();
                    if (!hasErrors) {
                        setRepoInfoErrorText("");
                        setActiveStepIndex(detail.requestedStepIndex);
                    } else {
                        setRepoInfoErrorText("Please resolve all the errors before continuing.");
                    }
                    break;
                case 2:
                    if (selectedRules.length === 0) {
                        setRuleSelectionErrorText("Please select at least one rule.");
                    } else {
                        setActiveStepIndex(detail.requestedStepIndex);
                    }
                    break;
                default:
                    setActiveStepIndex(detail.requestedStepIndex);
                    break;
            }
        } else {
            setActiveStepIndex(detail.requestedStepIndex);
        }
    }

    const handleSubmit = async () => {
        try {
            setSubmitLoading(true);
            await new Promise(e => setTimeout(e, 1e3));

            //TODO: Navigate to the id of newly created repository rule config
            navigate("df2fc38c-ee63-4cf3-9ba7-3dbde0150b2b");
            FlashbarPublisher.setItems([{
                id: "add_repo_rule_success",
                header: "Repository-specific rules added successfully.",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "It may take some time to propogate the rules to all client machines depending on their internet connections.",
            }]);
        } catch (error) {
            FlashbarPublisher.setItems([{
                id: "add_repo_rule_error",
                header: "Failed to add repository rules.",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error creating the repository rule configuration.",
            }]);
        }
        setSubmitLoading(false);
    }

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            { text: "Code Defender", href: "/" },
        ];
        breadcrumbs.push(
            { text: "Repository Rules", href: "/repo_rules" },
            { text: "Add Repository rules", href: `/repo_rules/#addRepo` }
        );

        return breadcrumbs;
    }

    return (
        <SpaceBetween direction='vertical' size='s'>
            <BreadcrumbGroup
                test-id="breadcrumb-group"
                items={getBreadcrumbs()}
                ariaLabel="Breadcrumbs"
                onClick={(ev) => {
                    ev.preventDefault();
                    navigate(ev.detail.href);
                }}
            />
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    skipToButtonLabel: (step, stepNumber) =>
                        `Skip to ${step.title}`,
                    navigationAriaLabel: "Steps",
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: "Add rules",
                    optional: "optional"
                }}
                onCancel={handleCancel}
                onNavigate={handleNavigate}
                onSubmit={handleSubmit}
                activeStepIndex={activeStepIndex}
                isLoadingNextStep={submitLoading}
                steps={[
                    {
                        title: "Add Repository Information",
                        content: (
                            <RepoInfoForm
                                repoUrl={repoUrl}
                                setRepoUrl={setRepoUrl}
                                urlErrorText={urlErrorText}
                                setUrlErrorText={setUrlErrorText}
                                ownersErrorText={ownersErrorText}
                                setOwnersErrorText={setOwnersErrorText}
                                additionalContributorsErrorText={additionalContributorsErrorText}
                                setAdditionalContributorsErrorText={setAdditionalContributorsErrorText}
                                additionalContributors={additionalContributors}
                                setAdditionalContributors={setAdditionalContributors}
                                owners={owners}
                                setOwners={setOwners}
                                additionalContributorsSelection={additionalContributorsSelection}
                                setAdditionalContributorsSelection={setAdditionalContributorsSelection}
                            />
                        ),
                        errorText: repoInfoErrorText ? (<> {repoInfoErrorText} </>) : null
                    },
                    {
                        title: "Choose rules",
                        description:
                            "These rules will apply to everyone under your management.",
                        content: (
                            <RulePicker
                                selectedRules={selectedRules}
                                setSelectedRules={setSelectedRules}
                                existingRules={[]}
                            />
                        ),
                        errorText: ruleSelectionErrorText ? (<> {ruleSelectionErrorText} </>) : null
                    },
                    {
                        title: "Choose enforcement types",
                        description:
                            "Review rules selected and choose how you want to enforce it.",
                        content: (

                            <SpaceBetween direction="vertical" size="l">
                                <ExpandableSection
                                    defaultExpanded
                                    variant="container"
                                    headerText="How to choose enforcement types?"
                                >
                                    <ColumnLayout
                                        borders="vertical"
                                        columns={3}
                                        variant="text-grid"
                                    >
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.DISABLED)}<div>Rule will not be scanned at this stage. This type is often used to reduce performance time for frequent commits.</div></div>
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.WARNING)}<div>User will be warned about detection of the pattern. However, they can still complete the commit or push. This type is often used for suggesting best practice.</div></div>
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.BLOCKING)}<div>Commit or push will not be completed upon detection of the pattern. This type is often used for security and compliance reasons.</div></div>
                                    </ColumnLayout>
                                </ExpandableSection>
                                <EnforcementPicker
                                    selectedRules={selectedRules}
                                    ruleEnforcements={ruleEnforcements}
                                    setRuleEnforcements={setRuleEnforcements}
                                />
                                <Alert
                                    statusIconAriaLabel="Info"
                                >
                                    All repository contributors will be affected by this change.
                                </Alert>
                            </SpaceBetween>
                        )
                    },
                ]}
            />
        </SpaceBetween>
    );
}
