import { DataAccessService } from "../../core/api";

// Hit the search endpoint for repos. This endpoint requires username for 
// authentication, a search parameters object that should contain:
// - searchText: The value used to perform the search. This is optional
// - managers: An array of the aliases of managers to include in the search
// - excludeOpenSource: boolean indicating whether the open source repos 
//            should be excluded from the search
// - lastUrl: This is used for pagination. It indicates the last url that was
//            returned in the previous page, and indicates where to start on the
//            next page
// - lastManager: This is used for pagination. It indicates the last manager 
//            that was returned in the previous page, and indicates where to 
//            start on the next page
// The returned structure will have 
// - repos: list of the approved repositories for the user that match the search
// - search: A duplicate of the search param object used
// - nextPage: An object with the same form as searchParams that can be used
//            to return the next page of results in the search
export async function searchRepos(
    searchParams,
    url = '/repos/search'
) {

    const client = new DataAccessService({
        baseURL: `${window.location.protocol}//${window.location.host}`
    });

    // shallow copy
    searchParams = { ...searchParams };

    // replace the managers array with a concatenated version
    searchParams.managers = searchParams.managers?.join(',');

    try {
        let searchResults = await client.get(url, undefined, searchParams);
        return searchResults;
    }
    catch (err) {
        console.warn(err);
        throw err;
    }
}
