import { useCollection } from "@amzn/awsui-collection-hooks";
import { Box, Button, Header, Pagination, Select, SpaceBetween, Table, TextFilter } from "@amzn/awsui-components-react";
import { useState } from "react";
import { getMatchesCountText } from "../../../helpers/getMatchesCountText";
import { getEnforcementTypeSelectOptions, getRenderedEnforcementType, getRenderedName } from "../../../helpers/rules/utils";

export const RepoRulesTab = ({ rules, loading, isOwner }) => {
    // State
    const [selectedRules, setSelectedRules] = useState([]);

    // List of table ids, also used for defining which attribute on item to sort and filter
    const name = "name";
    const description = "description";
    const categories = "categories";
    const commitEnforcement = "commitEnforcement";
    const pushEnforcement = "pushEnforcement";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        rules,
        {
            pagination: {
                pageSize: 10,
            },
            filtering: {
                fields: [name, description, categories],
            },
            sorting: {},
            selection: {}
        }
    );

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    counter={selectedRules.length ? `(${selectedRules.length}/${rules.length})` : `(${rules.length})`}
                    actions={
                        isOwner() &&
                        <SpaceBetween direction='horizontal' size='s'>
                            <Button
                                data-testid={'delete-repo-rules-button'}
                                variant='normal'
                                disabled={selectedRules.length === 0}
                            >
                                Delete
                            </Button>
                            <Button data-testid={'add-repo-rules-button'}
                                variant='primary'
                                onClick={() =>
                                    window.location.hash = "#addRules"}>
                                Add Rules
                            </Button>
                        </SpaceBetween>
                    }
                    description="These rules apply to contributors and additional contributors of this repository."

                >
                    Repository-specific rules
                </Header>
            }
            selectionType={isOwner() ? 'multi' : undefined}
            onSelectionChange={({ detail }) => {
                setSelectedRules(detail.selectedItems);
            }}
            selectedItems={selectedRules}
            items={items}
            loading={loading}
            loadingText='Loading team rules...'
            columnDefinitions={
                [
                    {
                        id: name,
                        header: "Rule name",
                        cell: item => getRenderedName(item),
                        isRowHeader: true,
                        sortingField: name
                    },
                    {
                        id: categories,
                        header: "Categories",
                        cell: item => item.categories.join(', '),
                        sortingField: categories
                    },
                    {
                        id: commitEnforcement,
                        header: "Commit enforcement",
                        cell: item => getRenderedEnforcementType(item.precommit),
                        sortingField: 'precommit',
                        editConfig: isOwner() && {
                            ariaLabel: "Commit enforcement",
                            editIconAriaLabel: "editable",
                            editingCell: (
                                item,
                                { currentValue, setValue }
                            ) => {
                                const value = currentValue ?? item.precommit;
                                return (
                                    <Select
                                        autoFocus={true}
                                        expandToViewport={true}
                                        selectedOption={
                                            getEnforcementTypeSelectOptions().find(
                                                option => option.value === value
                                            ) ?? null
                                        }
                                        onChange={event => {
                                            setValue(
                                                event.detail.selectedOption.value ??
                                                item.precommit
                                            );
                                        }}
                                        options={getEnforcementTypeSelectOptions()}
                                    />
                                );
                            },

                        }
                    },
                    {
                        id: pushEnforcement,
                        header: "Push enforcement",
                        cell: item => getRenderedEnforcementType(item.prepush),
                        sortingField: 'prepush',
                        editConfig: isOwner() && {
                            ariaLabel: "Push enforcement",
                            editIconAriaLabel: "editable",
                            editingCell: (
                                item,
                                { currentValue, setValue }
                            ) => {
                                const value = currentValue ?? item.prepush;
                                return (
                                    <Select
                                        autoFocus={true}
                                        expandToViewport={true}
                                        selectedOption={
                                            getEnforcementTypeSelectOptions().find(
                                                option => option.value === value
                                            ) ?? null
                                        }
                                        onChange={event => {
                                            setValue(
                                                event.detail.selectedOption.value ??
                                                item.prepush
                                            );
                                        }}
                                        options={getEnforcementTypeSelectOptions()}
                                    />
                                );
                            }
                        },
                    }
                ]
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search rules"
                />
            }
            pagination={
                <Box float="right">
                    < Pagination
                        {...paginationProps}
                        disabled={loading}
                    />
                </Box>
            }
            empty="No rules to display"

        />);
}