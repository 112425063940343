const jwt = require('jsonwebtoken');
export default class LocalStorage {

    static get(key) {
        let val
        let item = localStorage.getItem(key);
        try {
            val = JSON.parse(item)
        } catch {
            val = item
        }
        return val
    }

    static getIdToken() {
        let userInfo = {};
        for (let [key, val] of Object.entries(localStorage)) {
            if (key.includes('idToken')) {
                let decoded = jwt.decode(val, { complete: true });
                let payload = decoded['payload']

                userInfo = {
                    email: payload.email ?? '',
                    family_name: payload.family_name ?? '',
                    groups: payload['cognito:groups'] ?? [],
                    name: payload.name ?? '',
                    preferred_username: payload.preferred_username ?? '',
                    username: payload['cognito:username'] ?? ''
                }
                return userInfo;
            }
        }
        throw Error(`Unable to find the idToken`)
    }

    static set(key, value) {
        let val
        try {
            val = JSON.stringify(value)
        } catch {
            val = value
        }
        localStorage.setItem(key, val);
    }
}