
// Parse the given role string into an object like
// {
//     kind:"Delegate"
//     body:{
//         managerAlias:"zhaan",
//         managerChain:["moya", "pilot"]
//     }
// }
const parseRole = (roleStr) => {
    if (!roleStr) {
        return undefined;
    }

    const bracePosition = roleStr.indexOf('{');
    let kind;
    let body

    if (bracePosition >= 0) {
        kind = roleStr.substring(0, bracePosition);
        body = JSON.parse(roleStr.substring(bracePosition));
    } else {
        kind = roleStr;
        body = {};
    }

    return {
        kind,
        body
    };
}

// Get the list of managers a given role gives a user access to
const accessibleManagerReposForRole = (userDetails, role) => {
    switch (role.kind) {
        case "Admin": return [userDetails.username];
        case "Delegate": return [role.body.managerAlias, ...role.body.managerChain];
        case "Contributor": return role.body.chain;
        default: return [];
    }
}

// Get the list of managers whose repos are accessible to the given user based
// on their details
export const getRepoManagersForUser = (userDetails) => {
    if (!userDetails) {
        return [];
    }

    const chain = userDetails.chain || [];
    const roles = userDetails.roles || [];

    return chain.concat(roles
        .map(parseRole)
        .flatMap(role => accessibleManagerReposForRole(userDetails, role))
    ).filter(manager => manager !== 'ROOT');
}