
import { Navigate } from 'react-router-dom';
import { isDefenderInstalled } from '../helpers/isDefenderInstalled';
import { isNewDashboardAccessible } from '../helpers/isNewDashboardAccessible';
import { FlashbarPublisher } from './FlashbarPublisher';
import { isRulesEngineAccessible } from '../helpers/isRulesEngineAccessible';
import { hasAdminLikeRole } from '../helpers/hasAdminLikeRole';

// Name of the storage key for the path used to redirect after auth process
export const AUTH_REDIRECT = "auth-redirect";

// This function handles the default routing for the app. When someone goes to
// https://codedefender.blah they will get routed to a different page based on
// the properties of their user account
export default function DefaultRedirection() {
    const redirectLocation = localStorage.getItem(AUTH_REDIRECT);

    if (redirectLocation !== null) {
        localStorage.removeItem(AUTH_REDIRECT);
        return <Navigate to={redirectLocation} />;
    }

    FlashbarPublisher.clear();

    // Default landing page depends on the properties of the user
    if (hasAdminLikeRole() && isRulesEngineAccessible()) {
        return <Navigate to='/rules' />;
    } else if (isDefenderInstalled()) {
        if (isNewDashboardAccessible()) {
            return <Navigate to='/dashboard' />;
        } else {
            return <Navigate to='/view_metrics' />;
        }
    } else {
        return <Navigate to='/installation' />;
    }
}
