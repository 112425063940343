import { DataAccessService } from "../core/api";
import { User } from "../core/user";

export default async function getBypasses() {
    try {
        const client = new DataAccessService();
        const user = new User();
        const requestParams = {
            username: user.userId,
            type: "COMMIT_NOT_VERIFIED",
            startDate: toRFC3339Nano(new Date(new Date().setDate(new Date().getDate() - 89))),
            endDate: toRFC3339Nano(new Date())
        }
        const response = await client.get(`/metrics`, undefined, requestParams);
        return response.metricsEntries;
    }
    catch (err) {
        console.warn(err);
        throw err;
    }
}

function toRFC3339Nano(date) {
    const pad = (num) => (num < 10 ? '00' : num < 100 ? '0' : '');
    const isoString = date.toISOString();
    const nanoseconds = date.getMilliseconds() * 1000000;
    const padding = pad(date.getMilliseconds())
    return `${isoString.substring(0, isoString.indexOf("."))}.${padding}${nanoseconds}Z`;
}