import { useEffect, useState } from "react";
import ServiceAppLayout from "../../components/ServiceAppLayout";
import { Repos } from "../../components/repo-rules-ui/Repos";
import { useLocation } from "react-router-dom";
import { AddRepoRules } from "../../components/repo-rules-ui/modify-ui/AddRepoRules";
import { hasAdminLikeRole } from "../../helpers/hasAdminLikeRole";

export const ReposPage = () => {
    const location = useLocation();
    const [showAddPage, setShowAddPage] = useState(false);

    useEffect(() => {
        setShowAddPage(location.hash === '#addRepo' && hasAdminLikeRole());
    }, [location.hash])

    return (
        <div>
            {
                <ServiceAppLayout
                    content={
                        showAddPage ?
                            <AddRepoRules /> :
                            <Repos />
                    }
                />
            }
        </div>
    )
};
