const domain = window.location.host;
const isLocal = domain === "localhost:3000";
const protocol = isLocal ? "http" : "https";

let devWebConfig = {};
try {
    if (isLocal) devWebConfig = require("./dev-website-config").default;
} catch (e) {
    /**
     * Purposefully logging error instead of throwing it. "dev-website-config.ts" is ONLY needed for local development.
     * This try/catch statement acts as a conditional import for "dev-website-config.ts". Without it when `npm run build`
     * is ran in `/frontend` during the build phase of the CI/CD pipeline it will present the below error message and stop
     * the build:
     * ```
     *  Failed to compile.
     *
     *  ./src/ts/amplifyConfig.ts
     *  Cannot find file './dev-website-config' in './src/js'.
     * ```
     * By logging the error, the build is able to complete and the error will be caught during runtime if devWebConfig is
     * attempted to be used, which will NEVER happen outside of your local machine (aka laptop). In other
     * words, THIS WILL NOT IMPACT the application during runtime if it is deployed/running from your developer account or
     * the production account.
     */
    console.log(
        "You are seeing this warning because `dev-website-config.js` has not yet been created. \n" +
        "Please run `create-dev-web-config.js` which can be found under `/frontend/scripts`."
    );
    console.warn(e);
}

const config = {
    region: isLocal ? devWebConfig["region"] : window["cdConfig"].region,
    identityPoolRegion: isLocal ? devWebConfig["region"] : window["cdConfig"].region,
    userPoolId: isLocal ? devWebConfig["userPoolId"] : window["cdConfig"].userPoolId,
    userPoolWebClientId: isLocal ? devWebConfig["userPoolWebClientId"] : window["cdConfig"].userPoolWebClientId,
    mandatorySignIn: false,
    oauth: {
        domain: isLocal ? devWebConfig["cognitoDomain"] : window['cdConfig'].cognitoDomain,
        scope: ['email', 'openid', 'profile'],
        redirectSignIn: `${protocol}://${domain}`,
        redirectSignOut: `${protocol}://${domain}`,
        responseType: "code"
    }
}

export { config };
