import { AttributeEditor, Container, Form, FormField, Header, Input, SpaceBetween, Tiles } from "@amzn/awsui-components-react";

export const RepoInfoForm = ({
    repoUrl,
    setRepoUrl,
    urlErrorText,
    setUrlErrorText,
    ownersErrorText,
    setOwnersErrorText,
    additionalContributorsErrorText,
    setAdditionalContributorsErrorText,
    additionalContributors,
    setAdditionalContributors,
    owners,
    setOwners,
    additionalContributorsSelection,
    setAdditionalContributorsSelection
}) => {
    const AdditionalContributorsSelection = {
        SELECTED: 'selected',
        NOT_SELECTED: 'not_selected'
    };

    return (
        <Form>
            <SpaceBetween direction='vertical' size='l'>
                <Container
                    header={
                        <Header variant="h2">
                            Overview
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        <FormField label="Repository URL" errorText={urlErrorText}>
                            <Input
                                value={repoUrl}
                                onChange={({ detail }) => {
                                    setRepoUrl(detail.value);
                                    setUrlErrorText("");
                                }}
                                placeholder="https://www.example.com"
                            />
                        </FormField>
                        <FormField
                            label="Repository owners"
                            description="You can select owners under your management. For anyone outside your management chain please use the Delegate feature."
                            errorText={ownersErrorText}
                        >
                            <AttributeEditor
                                onAddButtonClick={() => {
                                    setOwners([...owners, ""]);
                                    setOwnersErrorText("");
                                }}
                                onRemoveButtonClick={({
                                    detail: { itemIndex }
                                }) => {
                                    const tmpItems = [...owners];
                                    tmpItems.splice(itemIndex, 1);
                                    setOwners(tmpItems);
                                    setOwnersErrorText("");
                                }}
                                items={owners}
                                addButtonText="Add additional repository owner"
                                removeButtonText="Remove"
                                definition={[
                                    {
                                        control: (item, itemIndex) => (
                                            <Input
                                                type="search"
                                                onChange={({ detail }) => {
                                                    const tmpItems = [...owners];
                                                    tmpItems[itemIndex] = detail.value;
                                                    setOwners(tmpItems);
                                                    setOwnersErrorText("");
                                                }}
                                                value={owners[itemIndex]}
                                                placeholder="Type alias"
                                            />
                                        )
                                    }
                                ]}
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
                <Container
                    header={
                        <Header variant="h2">
                            Choose repository contributors
                        </Header>
                    }
                >
                    <SpaceBetween direction="vertical" size="l">
                        Who will be contributing to this repository?
                        <Tiles
                            onChange={({ detail }) => {
                                setAdditionalContributorsSelection(detail.value);
                                if (detail.value === AdditionalContributorsSelection.NOT_SELECTED) {
                                    setAdditionalContributors([]);
                                } else {
                                    setAdditionalContributors([""]);
                                }
                            }}
                            value={additionalContributorsSelection}
                            items={[
                                {
                                    label: "All repository contributors are under owner's management",
                                    description: "Everyone under owner's management will be automatically added.",
                                    value: AdditionalContributorsSelection.NOT_SELECTED
                                },
                                {
                                    label: "I want to add additional repository contributors on top of everyone under owner's management",
                                    description: "Add additional people that contribute to the repository but outside of owner's management chain.",
                                    value: AdditionalContributorsSelection.SELECTED
                                }
                            ]}
                        />
                        {additionalContributorsSelection === AdditionalContributorsSelection.SELECTED &&
                            <FormField
                                label="Additional repository contributors"
                                errorText={additionalContributorsErrorText}
                            >
                                <AttributeEditor
                                    onAddButtonClick={() => setAdditionalContributors([...additionalContributors, ""])}
                                    onRemoveButtonClick={({
                                        detail: { itemIndex }
                                    }) => {
                                        if (additionalContributors.length === 1) {
                                            setAdditionalContributorsSelection(AdditionalContributorsSelection.NOT_SELECTED);
                                        }

                                        const tmpItems = [...additionalContributors];
                                        tmpItems.splice(itemIndex, 1);
                                        setAdditionalContributors(tmpItems);
                                        setAdditionalContributorsErrorText("");
                                    }}
                                    items={additionalContributors}
                                    addButtonText="Add additional contributor"
                                    removeButtonText="Remove"
                                    definition={[
                                        {
                                            control: (item, itemIndex) => (
                                                <Input
                                                    type="search"
                                                    onChange={({ detail }) => {
                                                        const tmpItems = [...additionalContributors];
                                                        tmpItems[itemIndex] = detail.value;
                                                        setAdditionalContributors(tmpItems);
                                                        setAdditionalContributorsErrorText("");
                                                    }}
                                                    value={additionalContributors[itemIndex]}
                                                    placeholder="Type alias"

                                                />
                                            ),
                                        }
                                    ]}
                                />
                            </FormField>}
                    </SpaceBetween>
                </Container>
            </SpaceBetween>
        </Form>
    );
}