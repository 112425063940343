import React from 'react';
import ReactDOM from 'react-dom';
import ES6Promise from "es6-promise";
import Router from './js/router';
 
ES6Promise.polyfill();

ReactDOM.render(
  <Router />,
  document.getElementById('root')
);