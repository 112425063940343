export const OSType = {
    Unidentifiable: "Not Recognized",
    Mac: "macOS",
    Windows: "Windows",
    RedHatLinux: "Red Hat Linux",
    DebianLinux: "Debian Linux"
}

export const identifyOS = () => {
    if (navigator.userAgent.search(/Mac OS/) !== -1) {
        return OSType.Mac;
    } else if (navigator.userAgent.search(/Win64/) !== -1) {
        return OSType.Windows;
    } else if (navigator.userAgent.search(/Red Hat/) !== -1) {
        return OSType.RedHatLinux;
    } else if (navigator.userAgent.search(/Debian/)) {
        return OSType.DebianLinux
    } else {
        return OSType.Unidentifiable;
    }
}