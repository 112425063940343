import { BreadcrumbGroup, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { User } from '../../core/user';
import { hasAdminLikeRole } from "../../helpers/hasAdminLikeRole";
import { getRuleConfigs } from "../../helpers/rules/getRuleConfigs";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { InheritedRulesTable } from "./InheritedRulesTable";
import { TeamRulesTable } from "./TeamRulesTable";

export const Rules = ({ setShowAddPage, setTeamConfig }) => {

    const user = new User();
    const [teamRules, setTeamRules] = useState([]);
    const [inheritedRules, setInheritedRules] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchRuleConfigs = async () => {
        try {
            setLoading(true);
            const result = await getRuleConfigs();
            const teamRuleConfig = result.filter(({ manager }) => manager?.username === user.userId);
            const inheritedRuleConfigs = result.filter(({ manager }) => manager && manager.username !== user.userId);
            setTeamRules(teamRuleConfig.length > 0 ? teamRuleConfig[0].rules : []);
            setTeamConfig(teamRuleConfig.length > 0 ? teamRuleConfig[0] : {});
            setInheritedRules(inheritedRuleConfigs.flatMap(({ rules, manager, ...metadata }) => rules.map(rule => ({ ...rule, manager }))));
        } catch (e) {
            console.error(e);
            FlashbarPublisher.setItems([{
                id: "fetch_rule_configs_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error fetching your rule configurations."
            }]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRuleConfigs();
    }, []);

    return (
        <div data-testid={"rules"}>
            <SpaceBetween direction='vertical' size='s'>
                <BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={[
                        { text: "Code Defender", href: "/" },
                        { text: "Rules", href: "/rules" }
                    ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate(ev.detail.href);
                    }}
                />

                <Header variant="h1" description="Manage rules by organization structure.">Rules</Header>
                <div data-testid={"repository-tab"}>
                    <SpaceBetween size='xxl' direction='vertical'>
                        {hasAdminLikeRole() &&
                            <div data-testid={"your-rules-table"}>
                                <TeamRulesTable
                                    rules={teamRules}
                                    loading={loading}
                                    refresh={fetchRuleConfigs}
                                    setShowAddPage={setShowAddPage}
                                />
                            </div>}
                        <div data-testid={"inherited-rules-table"}>
                            <InheritedRulesTable
                                rules={inheritedRules}
                                loading={loading}
                                refresh={fetchRuleConfigs}
                            />
                        </div>
                    </SpaceBetween>
                </div>
            </SpaceBetween>
        </div>
    );
}