import React, { useEffect, useState } from "react";

import {
    Button,
    ColumnLayout,
    Container,
    Form,
    FormField,
    Grid,
    Header,
    Input,
    Popover,
    SpaceBetween,
    StatusIndicator,
    TextContent
} from "@amzn/awsui-components-react";

import { getRegistrationEntry } from "../../helpers/getRegistrationEntry";
import { DataAccessService } from "../../core/api";
import { User } from "../../core/user";

export const RegistrationDetails = ({ setFlashBarError }) => {
    const [ token, setToken ] = useState("");
    const [tokenLoading, setTokenLoading] = useState(true);
    const [tokenError, setTokenError] = useState(false);

    const client = new DataAccessService();
    const user = new User();

    useEffect(() => {
        const fetchToken = async () => {
            let token;
            try {
                let result = await getRegistrationEntry(
                    client,
                    {},
                    {
                        username: user.email || "",
                        loggedInUser: user.userId
                    },
                    `${window.location.protocol}//${window.location.host}/registration`
                );
                token = result[0].userPassword;
            } catch (e) {
                token = "";
                setFlashBarError();
                setTokenError(true);
            }
            setToken(token);
            setTokenLoading(false);
        };

        fetchToken();
    }, []);

    return (
        <div data-testid={"registration-details"}>
            <Grid gridDefinition={[{ "colspan": 6, "offset": 2}]}>
                <ColumnLayout borders={"vertical"} columns={1}>
                    <form onSubmit={e => e.preventDefault()}>
                        <Form>
                            <Container
                                header={
                                    <Header variant={"h2"}>
                                        Installer Registration
                                    </Header>
                                }
                            >
                                <SpaceBetween size={"l"} direction={"vertical"}>
                                    <FormField label={"Copy your registration token"}>
                                        <Input readOnly={true} value={token} />
                                    </FormField>
                                    <CopyTokenPopover tokenLoading={tokenLoading} tokenError={tokenError}>
                                        <SpaceBetween direction={"horizontal"} size={"xs"}>
                                            <Button
                                                iconName={"copy"}
                                                variant={"primary"}
                                                disabled={tokenLoading}
                                                loading={tokenLoading}
                                                formAction={"none"}
                                                onClick={async () => {
                                                    return await navigator.clipboard.writeText(token);
                                                }}
                                            >
                                                Copy registration token
                                            </Button>
                                        </SpaceBetween>
                                    </CopyTokenPopover>
                                    <TextContent>
                                        <p>
                                            After you have copied the registration token, please return to the installer
                                            and paste your token.
                                        </p>
                                    </TextContent>
                                </SpaceBetween>
                            </Container>
                        </Form>
                    </form>
                </ColumnLayout>
            </Grid>
        </div>
    );
}

/**
 * Dev Tip!
 * The below component applies the pattern of composition. As shown above, this component wraps the button. This is made
 * possible via composition, where the to-be children of this component can be referenced as props.children.
 */
const CopyTokenPopover = (props) => {
    const [type, setType] = useState("info");
    const [text, setText] = useState("Token is loading");

    useEffect(() => {
        if (!props.tokenLoading) {
            if (props.tokenError) {
                setType("error");
                setText("Copying token failed");
            } else {
                setType("success");
                setText("Token copied to clipboard")
            }
        }
    }, [props.tokenLoading, props.tokenError]);

    return (
        <Popover
            size={"medium"}
            position={"right"}
            triggerType={"custom"}
            dismissButton={false}
            content={<StatusIndicator type={type}>{text}</StatusIndicator>}
        >
            {props.children}
        </Popover>
    );
};
