//React
import React, { Component } from "react";
import { DataAccessService } from 'Core/api';

// Polaris
import {
    Badge,
    Box,
    BreadcrumbGroup,
    Button,
    ColumnLayout,
    Container,
    FormField,
    Header,
    Input,
    LineChart,
    Link,
    Pagination,
    SegmentedControl,
    SpaceBetween,
    Spinner,
    Table,
    Tabs,
} from '@amzn/awsui-components-react';

import { personalRisk, riskColumnDefinitions, totalRisks } from 'Helper/riskMetrics';
import riskModal from '../../../helpers/riskDetailsModal';
import { formatRiskGraphData } from 'Helper/formatRiskGraphData';
import getMetricsForUser from '../../../helpers/getMetricsForUser';
import getDelegationsForUser from '../../../helpers/getDelegationsForUser';
import installationStatus from '../../../helpers/installationStatus';
import blockedCommitModal from '../../../helpers/blockedCommitModal';
import { organizeBlockedCommits, organizeMetrics, organizeUserIssueList } from 'Helper/organizeData';
import { negativeIconBuilder, positiveIconBuilder } from 'Helper/iconBuilders'
import { User } from "Core/user";

import { LocalStorage } from "Core/storage";
import { LeaveIcon } from "../../../helpers/iconBuilders";
import { useOutletContext } from "react-router-dom";


export const Risks = ({ }) => {

    const {
        reportItems,
        riskGraphData,
        setRiskGraphData,
        riskGraphType,
        setRiskGraphType,
        riskCounts,
        riskData,
        visibleRiskGraphData,
        setVisibleRiskGraphData,
        loading,
        setModalToRiskData,
        setModalVisibility
 } = useOutletContext();

    return (<ColumnLayout>
        <Container
            header={
                <Header
                    variant="h2"
                    actions={
                        (reportItems && reportItems.length > 0) &&
                        <SegmentedControl
                            selectedId={riskGraphType}
                            onChange={({ detail }) => {
                                const riskGraphData = formatRiskGraphData(riskCounts)
                                const selectedGraphData = detail.selectedId === "overall" ? riskGraphData.overallData : riskGraphData.personalData
                                setVisibleRiskGraphData(selectedGraphData);
                                setRiskGraphData(selectedGraphData);
                                setRiskGraphType(detail.selectedId);
                            }
                            }
                            label="Risk graph segmented control"
                            options={[
                                { text: "Overall", id: "overall" },
                                { text: "Personal", id: "personal" },
                            ]}
                        />
                    }
                >
                    Risk Trends
                </Header>
            }
        >
            <LineChart
                series={riskGraphData}
                visibleSeries={visibleRiskGraphData}
                i18nStrings={{
                    filterLabel: "Filter by risk",
                    filterPlaceholder: "Filter risk type",
                    filterSelectedAriaLabel: "selected",
                    legendAriaLabel: "Legend",
                    chartAriaRoleDescription: "line chart",
                    xTickFormatter: e =>
                        e
                            .toLocaleDateString("en-US", {
                                month: "short",
                                day: "numeric",
                            })
                            .split(",")
                            .join("\n"),
                    yTickFormatter: undefined
                }}
                ariaLabel="Single data series line chart"
                detailPopoverSize="large"
                errorText="Error loading data"
                height={300}
                loadingText="Loading chart"
                recoveryText="Retry"
                statusType={loading ? "loading" : "finished"}
                xScaleType="time"
                xTitle="Date"
                yScaleType="linear"
                yTitle="Number of Risks"
                empty={
                    <Box textAlign="center" color="inherit">
                        <b>No data available</b>
                        <Box variant="p" color="inherit">
                            There is no data available
                        </Box>
                    </Box>
                }
                noMatch={
                    <Box textAlign="center" color="inherit">
                        <b>No matching data</b>
                        <Box variant="p" color="inherit">
                            There is no matching data to display
                        </Box>
                        <Button onClick={
                            async () => {
                                setVisibleRiskGraphData(riskGraphData);
                            }
                        }>
                            Clear filter
                        </Button>
                    </Box>
                }
                onFilterChange={({ detail }) => {
                    setVisibleRiskGraphData(detail.visibleSeries);
                }
                }
            />
        </Container>
        <Container disableContentPaddings={true}>
            <Table
                columnDefinitions={riskColumnDefinitions(setModalToRiskData, setModalVisibility)}
                items={riskData.riskList}
                resizableColumns
                loadingText="Loading data"
                loading={loading}
                empty={<div>No data</div>}
                header={
                    (reportItems && reportItems.length > 0) ?
                        <Header
                            info={<Link variant="info"
                                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#34">Info</Link>}>
                            All Active Risks
                        </Header> :
                        <Header
                            info={<Link variant="info"
                                href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#34">Info</Link>}>
                            Your Active Risks
                        </Header>
                }

            />
        </Container>
    </ColumnLayout>);
}