import { Navigate } from "react-router-dom";


import ServiceAppLayout from "../components/ServiceAppLayout";
import { isRulesEngineAccessible } from "../helpers/isRulesEngineAccessible";

export const PageNotFound = () => {

    return (
        <div>
            {(isRulesEngineAccessible() ? null : <Navigate to="/" />)}
            <ServiceAppLayout
                content={
                    <div>
                        <Content />
                    </div>
                }
            />
        </div>
    );
};

const Content = () => {
    return (
        <div className="awsui-grid awsui-util-p-s generic-not-found">
            <div className="generic-not-found__container custom-awsui-util-pt-xxxl awsui-row">
                <div className="col-xxs-12">
                    <div className="generic-not-found__content col-xxxs-12 align-center">
                        <div className="generic-not-found__header">
                            <h1>404</h1>
                        </div>
                    </div>
                    <div className="generic-not-found__content col-xxxs-12 align-center">
                        <div className="align-center color-primary">
                            <h1>Sorry, this URL does not exist or is no longer available.</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
