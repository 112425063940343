export default async function getDelegationsForUser(client, data, params) {
    try {
        let confirmation = await client.post('/delegations', data, params);
        return confirmation;
    }
    catch (err) {
        console.warn(err);
        throw err;
    }
}
