import { Alert, BreadcrumbGroup, ColumnLayout, ExpandableSection, SpaceBetween, Wizard } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RuleEnforcementType } from "../../../core/constants";
import { getRenderedEnforcementType } from "../../../helpers/rules/utils";
import { FlashbarPublisher } from "../../FlashbarPublisher";
import { EnforcementPicker } from "./EnforcementPicker";
import { RulePicker } from "./RulePicker";

export const AddRules = ({ ruleConfig }) => {
    const [activeStepIndex, setActiveStepIndex] = useState(0);
    const [selectedRules, setSelectedRules] = useState([]);
    const [ruleEnforcements, setRuleEnforcements] = useState({});
    const [ruleSelectionErrorText, setRuleSelectionErrorText] = useState("");
    const [submitLoading, setSubmitLoading] = useState(false);
    const navigate = useNavigate();

    const defaultEnforcement = {
        commitEnforcement: RuleEnforcementType.WARNING,
        pushEnforcement: RuleEnforcementType.BLOCKING,
    }

    // Team rules contain a manager while repo rules do not
    const isTeamRule = () => ruleConfig.manager !== undefined;

    useEffect(() => {
        selectedRules.forEach(rule => {
            if (!ruleEnforcements[rule.id]) {
                setRuleEnforcements({ [rule.id]: defaultEnforcement, ...ruleEnforcements });
            }
        });
        setRuleSelectionErrorText(null);
    }, [selectedRules]);

    const handleCancel = () => {
        window.location.hash = "";
    }

    const handleNavigate = ({ detail }) => {
        if (detail.reason === 'next' && selectedRules.length === 0) {
            setRuleSelectionErrorText("Please select at least one rule.");
        } else {
            setActiveStepIndex(detail.requestedStepIndex);
        }
    }

    const handleSubmit = async () => {
        setSubmitLoading(true);

        // TODO: implement POST API endpoint
        await new Promise(e => setTimeout(e, 1e3));
        window.location.hash = "";
        FlashbarPublisher.setItems([{
            id: "add_rules_success",
            header: `${selectedRules.length > 1 ? `${selectedRules.length} rules` : `${selectedRules.length} rule`} added successfully.`,
            type: "success",
            dismissible: true,
            dismissLabel: "Dismiss message",
            onDismiss: FlashbarPublisher.clear,
            content: "It may take some time to propogate your changes to all client machines depending on their internet connections."
        }]);
        setSubmitLoading(false);
    }

    const getBreadcrumbs = () => {
        const breadcrumbs = [
            { text: "Code Defender", href: "/" },
        ];
        if (isTeamRule()) {
            breadcrumbs.push(
                { text: "Rules", href: "/team_rules" },
                { text: "Add rules", href: "/team_rules#addRules" }
            );
        } else {
            breadcrumbs.push(
                { text: "Repository Rules", href: "/repo_rules" },
                { text: ruleConfig.url, href: `/repo_rules/${ruleConfig.id}` },
                { text: "Add rules", href: `/repo_rules/${ruleConfig.id}#addRules` }
            );
        }

        return breadcrumbs;
    }

    return (
        <SpaceBetween direction='vertical' size='s'>
            <BreadcrumbGroup
                test-id="breadcrumb-group"
                items={getBreadcrumbs()}
                ariaLabel="Breadcrumbs"
                onClick={(ev) => {
                    ev.preventDefault();
                    navigate(ev.detail.href);
                }}
            />
            <Wizard
                i18nStrings={{
                    stepNumberLabel: stepNumber =>
                        `Step ${stepNumber}`,
                    collapsedStepsLabel: (stepNumber, stepsCount) =>
                        `Step ${stepNumber} of ${stepsCount}`,
                    skipToButtonLabel: (step, stepNumber) =>
                        `Skip to ${step.title}`,
                    navigationAriaLabel: "Steps",
                    cancelButton: "Cancel",
                    previousButton: "Previous",
                    nextButton: "Next",
                    submitButton: "Add rules",
                    optional: "optional"
                }}
                onCancel={handleCancel}
                onNavigate={handleNavigate}
                onSubmit={handleSubmit}
                activeStepIndex={activeStepIndex}
                isLoadingNextStep={submitLoading}
                steps={[
                    {
                        title: "Choose rules",
                        description:
                            "These rules will apply to everyone under your management.",
                        content: (
                            <RulePicker
                                selectedRules={selectedRules}
                                setSelectedRules={setSelectedRules}
                                existingRules={ruleConfig ? ruleConfig.rules : []}
                            />
                        ),
                        errorText: ruleSelectionErrorText ? (<> {ruleSelectionErrorText} </>) : null
                    },
                    {
                        title: "Choose enforcement types",
                        description:
                            "Review rules selected and choose how you want to enforce it.",
                        content: (

                            <SpaceBetween direction="vertical" size="l">
                                <ExpandableSection
                                    defaultExpanded
                                    variant="container"
                                    headerText="How to choose enforcement types?"
                                >
                                    <ColumnLayout
                                        borders="vertical"
                                        columns={3}
                                        variant="text-grid"
                                    >
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.DISABLED)}<div>Rule will not be scanned at this stage. This type is often used to reduce performance time for frequent commits.</div></div>
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.WARNING)}<div>User will be warned about detection of the pattern. However, they can still complete the commit or push. This type is often used for suggesting best practice.</div></div>
                                        <div>{getRenderedEnforcementType(RuleEnforcementType.BLOCKING)}<div>Commit or push will not be completed upon detection of the pattern. This type is often used for security and compliance reasons.</div></div>
                                    </ColumnLayout>
                                </ExpandableSection>
                                <EnforcementPicker
                                    selectedRules={selectedRules}
                                    ruleEnforcements={ruleEnforcements}
                                    setRuleEnforcements={setRuleEnforcements}
                                />
                                <Alert
                                    statusIconAriaLabel="Info"
                                >
                                    {isTeamRule() ? "Everyone under your management will be affected by this change. It will also overwrite managers under you if they have the same rules set up for their team." :
                                        "All repository contributors will be affected by this change."}
                                </Alert>
                            </SpaceBetween>
                        )
                    },
                ]}
            />
        </SpaceBetween>
    );
}
