// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    Button,
    Header,
    Pagination,
    SpaceBetween,
    Table,
    TextFilter
} from '@amzn/awsui-components-react';
import { useState } from 'react';
import { getMatchesCountText } from '../../helpers/getMatchesCountText';
import { hasAdminLikeRole } from "../../helpers/hasAdminLikeRole";
import timeAgo from "../../helpers/timeAgo";
import { InternalLink } from "../internal-link";

export const ReposTable = ({ repos, loading, refresh }) => {
    // State
    const [selectedRepos, setSelectedRepos] = useState([]);

    // List of table ids, also used for defining which attribute on item to sort and filter
    const url = "url";
    const owners = "owners";
    const rules = "rules";
    const additionalContributors = "additionalContributors";
    const lastUpdated = "lastUpdated";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        repos,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [url, owners],
            },
            sorting: {},
            selection: {}
        }
    );

    return (<>
        <Table
            {...collectionProps}
            header={
                <Header
                    counter={selectedRepos.length ? `(${selectedRepos.length}/${repos.length})` : `(${repos.length})`}
                    actions={
                        hasAdminLikeRole() && 
                            <SpaceBetween direction='horizontal' size='s'>
                                <Button
                                    data-testid={'delete-repository-button'}
                                    variant='normal'
                                    disabled={selectedRepos.length === 0}
                                >
                                    Delete
                                </Button>
                                <Button data-testid={'add-repository-button'}
                                    variant='primary'
                                    onClick={() => window.location.hash = "#addRepo"}>
                                    Add repository-specific rules
                                </Button>
                            </SpaceBetween>
                    }
                    description="These rules apply to additional contributors and everyone under your management."

                >
                    Your repositories
                </Header>
            }
            selectionType={hasAdminLikeRole() ? 'multi': undefined}
            onSelectionChange={({ detail }) => {
                setSelectedRepos(detail.selectedItems);
            }}
            selectedItems={selectedRepos}
            items={items}
            loading={loading}
            loadingText='Loading your repository rules...'
            columnDefinitions={
                [
                    {
                        id: url,
                        header: "Repository",
                        cell: item => <InternalLink href={`${item.id}`}>{item.url}</InternalLink>,
                        isRowHeader: true,
                        sortingField: url
                    },
                    {
                        id: owners,
                        header: "Repository owners",
                        cell: item => item.owners?.map(owner => owner.fullName).join(', '),
                        sortingField: owners
                    },
                    {
                        id: rules,
                        header: "Rules",
                        cell: item => item.rules.length,
                        sortingField: rules,
                    },
                    {
                        id: additionalContributors,
                        header: "Additional contributors",
                        cell: item => item.additionalContributors?.map(contributor => contributor.fullName).join(', '),
                        sortingField: additionalContributors,
                    },
                    {
                        id: lastUpdated,
                        header: "Last updated",
                        cell: item => timeAgo(item.lastUpdated),
                        sortingField: lastUpdated,
                    }
                ]
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search repositories"
                />
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button iconName="refresh" variant="icon" disabled={loading} onClick={refresh} />
                        < Pagination
                            {...paginationProps}
                            disabled={loading}

                        />
                    </SpaceBetween>
                </Box>
            }
            empty="No repository rules to display"

        />
    </>);
}