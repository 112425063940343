import { useEffect, useState } from "react";
import ServiceAppLayout from "../../components/ServiceAppLayout";
import { Rules } from "../../components/team-rules-ui/Rules";
import { AddRules } from "../../components/team-rules-ui/add-ui/AddRules";
import { useLocation } from "react-router-dom";
import { User } from "../../core/user";

export const RulesPage = () => {
    const location = useLocation();
    const [showAddPage, setShowAddPage] = useState(false);
    const [teamConfig, setTeamConfig] = useState('');
    const user = new User();

    useEffect(() => {
        setShowAddPage(location.hash === '#addRules' && teamConfig.manager === user.userId);
    }, [location.hash])

    return (
        <div>
            {
                <ServiceAppLayout
                    content={
                        showAddPage ?
                            <AddRules ruleConfig={teamConfig} /> :
                            <Rules setShowAddPage={setShowAddPage} setTeamConfig={setTeamConfig} />
                    }
                />
            }
        </div>
    )
};
