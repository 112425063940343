import { useState } from "react";

import { DataAccessService } from "../../../core/api";
import { User } from "../../../core/user";
import { addDelegation } from "../../../helpers/delegation";

import {
    Box,
    Button,
    Form,
    FormField,
    Input,
    Modal,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { FlashbarPublisher } from "../../FlashbarPublisher";

export const AddDelegateModal = ({ isVisible, setIsVisible, delegateAliases, fetchDelegations }) => {
    const [aliasValue, setAliasValue] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const resetFormAndHideModal = () => {
        setAliasValue("");
        setErrorText("");
        setIsLoading(false);
        setIsVisible(false);
    };

    const addDelegate = async (alias) => {
        const user = new User();
        // Preliminary client error checks
        if (alias === "") {
            setErrorText("Alias cannot be empty.");
            return;
        }
        if (delegateAliases.includes(alias)) {
            setErrorText(`${alias} is already a delegate.`);
            return;
        }
        if (alias.length > 10) {
            setErrorText("Alias must be less than or equal to 10.");
            return;
        }
        if (!/^[a-z]+$/.test(alias)) {
            setErrorText("Alias can only consist of lower-cased alphabetic characters.");
            return;
        }
        if (alias === user.userId.toLowerCase()) {
            setErrorText("You cannot add yourself as a delegate.");
            return;
        }

        try {
            setIsLoading(true);
            const client = new DataAccessService();
            await addDelegation(client, { "alias": alias }, {},
                `${window.location.protocol}//${window.location.host}/delegations`
            );
            FlashbarPublisher.setItems([{
                id: "add_delegation_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Delegate is added successfully."
            }]);
            resetFormAndHideModal();
            await fetchDelegations();
        } catch (error) {
            const httpStatus = error.response?.status;
            switch (httpStatus) {
                case 400:
                    // When a 400 is returned, it refers to an "Invalid alias" error returned by EDS.
                    // The "Invalid alias" error is generic and means that the provided input cannot be parsed by EDS.
                    // No application-logic on EDS' side has occurred yet. In order to provide the user with more info,
                    // the above three preliminary client error checks were created to cover potential syntax errors,
                    // and hopefully only leave the below syntax error: "Alias can only consist of lower-cased alphabetic characters."
                    //
                    // THUS, there is a possibility that a syntax error captured here DOES NOT relate to the below error text.
                    // If that ever happens, we will need to modify the checks.
                    setErrorText("Alias can only consist of lower-cased alphabetic characters.");
                    setIsLoading(false);
                    break;
                case 422:
                    // If a 422 is returned, it means that the syntax of the provided alias is valid, BUT:
                    // 1) Either there is no employee that exists with that alias or the corresponding employee is no longer active, OR
                    // 2) Employee is active, but they are not part of the Code Defender user hierarchy.
                    let errorMessage = error.response?.data["Message"];
                    setErrorText(errorMessage);
                    setIsLoading(false);
                    break;
                case 401:
                    setErrorText("You are not authorized to add delegates.");
                    setIsLoading(false);
                    break;
                default:
                    // If it's none of the above 4xx errors, it is assumed to be a 5xx Internal Server Error.
                    FlashbarPublisher.setItems([{
                        id: "add_delegation_error",
                        type: "error",
                        dismissible: true,
                        dismissLabel: "Dismiss message",
                        onDismiss: FlashbarPublisher.clear,
                        content: "There was an error adding your Delegate."
                    }]);
                    resetFormAndHideModal();
            }
        }
    };

    return (
        <Modal
            data-testid={"add-delegate-modal"}
            onDismiss={() => { resetFormAndHideModal() }}
            visible={isVisible}
            header={"Add new delegation to others"}
            footer={
                <Box float="right">
                    <SpaceBetween direction={"horizontal"} size={"xs"}>
                        <Button
                            data-testid={"cancel-add-delegate-modal-button"}
                            variant="link"
                            onClick={() => { resetFormAndHideModal() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"add-delegate-modal-button"}
                            variant="primary"
                            loading={isLoading}
                            loadingText={"Processing add delegate request."}
                            onClick={async () => { await addDelegate(aliasValue) }}
                        >
                            Add
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <form onSubmit={event => event.preventDefault()}>
                <Form>
                    <SpaceBetween size={"l"} direction={"vertical"}>
                        <FormField
                            data-testid={"add-delegate-form-field"}
                            label={"Alias"}
                            errorText={errorText}
                            description={`Alias must be a non-empty, lower-cased, alphabetic string that contains no more than 10 characters.`}
                        >
                            <Input
                                onChange={({ detail }) => {
                                    setAliasValue(detail.value.toLowerCase());
                                    setErrorText("");
                                }}
                                value={aliasValue}
                                placeholder={"Input alias here"}
                                inputMode={"text"}
                            />
                        </FormField>
                    </SpaceBetween>
                </Form>
            </form>
        </Modal>
    );
};
