import { Auth } from 'aws-amplify';
import { AUTH_REDIRECT } from "../components/DefaultRedirection";

/**
 * This function can be called anywhere and from any context to immediately 
 * redirect the user's browser to the cognito login page.
 */
export const transferToLogin = () => {
    if (window.location.pathname !== '/') {
        localStorage.setItem(AUTH_REDIRECT, window.location.pathname);
    }
    Auth.federatedSignIn();
    return;
}