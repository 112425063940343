/*
    The intended use for this function is to provide a base URL in the client object being passed in
    If base URL provided, no URL parameter is necessary
    Only overwrite the default URL in the event that you are providing a whole URL
    
    standard example:
        client = new DataAccessService({ baseURL: "https://api." + window.location.host });
        result = await getMetricsForUser(this.client, {}, { username: user })

    overwrite URL example
        client = new DataAccessService();
        result = await getMetricsForUser(this.client, {}, {username: this.state.user, machineToken: "newtoken", userPassword: "pwd"}, "https://api." + window.location.host + '/registration')
*/

export const getRegistrationEntry = async (client, data, params, url="/registration") => {
    try {
        return await client.get(url, data, params);
    } catch(err) {
        console.warn(err);
        throw err;
    }
};
