import { BreadcrumbGroup, Header, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { hasAdminLikeRole } from "../../helpers/hasAdminLikeRole";
import { getRuleConfigs } from "../../helpers/rules/getRuleConfigs";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { InheritedReposTable } from "./InheritedReposTable";
import { ReposTable } from "./ReposTable";
import { isRepoOwner } from "../../helpers/rules/utils";

export const Repos = () => {
    const [repos, setRepos] = useState([]);
    const [inheritedRepos, setInheritedRepos] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchRuleConfigs = async () => {
        try {
            setLoading(true);
            const result = await getRuleConfigs();
            // Filter for repo rule configs. Repo rule configs contain urls while team rules do not
            const repoRuleConfigs = result.filter(({ url }) => url !== undefined);
            const userRepoConfigs = repoRuleConfigs.filter((ruleConfig) => ruleConfig.owners && isRepoOwner(ruleConfig));
            const inheritedRepoConfigs = repoRuleConfigs.filter((ruleConfig) => ruleConfig.owners && !isRepoOwner(ruleConfig));
            setRepos(userRepoConfigs);
            setInheritedRepos(inheritedRepoConfigs);
        } catch (e) {
            FlashbarPublisher.setItems([{
                id: "fetch_rule_configs_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error fetching your repository rules."
            }]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRuleConfigs();
    }, []);

    return (
        <div data-testid={"repos"}>
            <SpaceBetween direction='vertical' size='s'>
                <BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={[
                        { text: "Code Defender", href: "/" },
                        { text: "Repository Rules", href: "/repo_rules" }
                    ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate(ev.detail.href);
                    }}
                />

                <Header variant="h1" description="Manage repository-specific rules.">Repository Rules</Header>
                <div data-testid={"repository-tab"}>
                    <SpaceBetween size='xxl' direction='vertical'>
                        {(hasAdminLikeRole() || repos.length > 0) &&
                            < div data-testid={"your-repo-rules-table"}>
                                <ReposTable
                                    repos={repos}
                                    loading={loading}
                                    refresh={fetchRuleConfigs}
                                />
                            </div>}
                        <div data-testid={"inherited-repo-rules-table"}>
                            <InheritedReposTable
                                repos={inheritedRepos}
                                loading={loading}
                                refresh={fetchRuleConfigs}
                            />
                        </div>
                    </SpaceBetween>
                </div>
            </SpaceBetween>
        </div>
    );
}