//React
import React, { Fragment, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

//Pages
import MetricsHomePage from 'Page/MetricsHomePage';
import ServiceHomePage from 'Page/ServiceHomePage';
import { RegistrationDetailsPage } from "Page/registration-details/RegistrationDetailsPage";
import { PageNotFound } from 'Page/PageNotFound';

//Components
import ServiceHeader from "Component/ServiceHeader"
import ServiceFooter from "Component/ServiceFooter"
import ErrorBoundary from './ErrorBoundary'

//Util
import { LocalStorage } from "Core/storage";
import { Amplify, Auth } from 'aws-amplify';
import { config } from './amplifyConfig';

import '@amzn/awsui-global-styles/polaris.css';
import { isInternalUser } from "./helpers/isInternalUser";
import getCurrentUserDetails from './helpers/getCurrentUserDetails';
import { DataAccessService } from './core/api';
import { User } from './core/user';
import { FEATURE_FLAGS, INSTALL_STATUS, USER_DETAILS, DELEGATIONS } from './core/user/user';
import { DashboardPage } from './pages/dashboard/DashboardPage';
import { Personal } from './pages/dashboard/personal/Personal';
import { Reports } from './pages/dashboard/reports/Reports';
import { Risks } from './pages/dashboard/risks/Risks';
import { getInstallationStatus } from './helpers/get-installation-status';
import DefaultRedirection from './components/DefaultRedirection';
import { DelegationPage } from './pages/delegation/DelegationPage';
import { RulesEnginePage } from './pages/rules-engine/RulesEnginePage';
import { transferToLogin } from './helpers/transferToLogin';
import { RulesPage } from './pages/team-rules/RulesPage';
import { ReposPage } from './pages/repo-rules/ReposPage';
import { RepoDetailPage } from './pages/repo-rules/RepoDetailPage';
import { isRuleManagementAccessible } from './helpers/isRuleManagementAccessible';
import { getDelegations } from './helpers/delegation';
import { isTopRopeFeatureOn } from './helpers/isTopRopeFlagOn';
import { BypassesPage } from './pages/bypasses/BypassesPage';

Amplify.configure({
	Auth: config
})

function Router() {
	const [userLoaded, setUserLoaded] = useState(false);

	useEffect(() => {
		const authUser = async () => {
			let cognitoUser;
			try {
				cognitoUser = await Auth.currentAuthenticatedUser();
			} catch (e) {
				transferToLogin();
				return;
			}

			try {
				const client = new DataAccessService({
					baseURL: `${window.location.protocol}//${window.location.host}`
				});

				const getFeatureFlags = client.get(`/featureflags`);
				const getUserDetails = getCurrentUserDetails(client, (new User()).userId);
				const getInstallStatus = getInstallationStatus();
				const getDelegation = getDelegations(client, {}, {});

				let [featureFlags, userDetails, installStatus, delegations] = await Promise.all([
					getFeatureFlags,
					getUserDetails,
					getInstallStatus,
					getDelegation
				]);

				LocalStorage.set(FEATURE_FLAGS, featureFlags);
				LocalStorage.set(INSTALL_STATUS, installStatus);
				LocalStorage.set(USER_DETAILS, userDetails);
				LocalStorage.set(DELEGATIONS, delegations)
			} catch (e) {
				console.warn(`Error getting and storing user details - `, e);
			}

			setUserLoaded(true);
		};
		authUser();

	}, []);

	if (userLoaded) {
		return (
			<BrowserRouter forceRefresh={false}>
				<ErrorBoundary>
					<Fragment>
						<ServiceHeader />
						<div className="page">
							<Routes>
								<Route index element={<DefaultRedirection />} />
								<Route exact path="/installation" element={<ServiceHomePage />} />
								{isInternalUser() && <Route exact path="/view_metrics" element={<MetricsHomePage />} />}
								<Route exact path="/registration_details" element={<RegistrationDetailsPage />} />
								<Route path="/dashboard" element={<DashboardPage />}>
									<Route index element={<Personal />} />
									<Route path="reports" element={<Reports />} />
									<Route path="risks" element={<Risks />} />
								</Route>
								<Route path="/delegation" element={<DelegationPage />} />
								{isRuleManagementAccessible() && <Route path="/team_rules" element={<RulesPage />} />}
								{isRuleManagementAccessible() && <Route path="/repo_rules" element={<ReposPage />} />}
								{isRuleManagementAccessible() && <Route path="/repo_allow_list" element={<RulesEnginePage />} />}
								{isRuleManagementAccessible() && <Route path='/repo_rules/:id' element={<RepoDetailPage />} />}
								{isRuleManagementAccessible() &&
									<Route
										path="/rules"
										element={<Navigate to="/repo_allow_list" />}
									/>
								}
								{!isRuleManagementAccessible() && <Route path="/rules" element={<RulesEnginePage />} />}
								{isTopRopeFeatureOn() && <Route path="/bypasses" element={<BypassesPage />} />}
								<Route path="*" element={<PageNotFound />} />
							</Routes>
						</div>
						<ServiceFooter />
					</Fragment>
				</ErrorBoundary>
			</BrowserRouter>
		);
	} else {
		return (
			<div className="App">
				<div className='loader'></div>
			</div>
		)
	}
}

export default Router;
