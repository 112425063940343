import {
    Box, BreadcrumbGroup, Button, ColumnLayout, Container,
    Header,
    SpaceBetween,
    Spinner,
    Tabs
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import { ContributorsTab } from './ContributorsTab';
import { RepoRulesTab } from './RepoRulesTab';
import { useNavigate } from 'react-router-dom';
import timeAgo from '../../../helpers/timeAgo';
import { ValueWithLabel } from '../../value-with-label';
import { User } from '../../../core/user';
import { getRuleConfigs } from '../../../helpers/rules/getRuleConfigs';

export const RepoDetail = ({ id, ruleConfig, setRuleConfig }) => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const user = new User();

    useEffect(() => {
        loadRepoDetails(id);
    }, []);

    const isOwner = () => {
        return ruleConfig.owners.some(owner => owner.username === user.userId);
    }

    const loadRepoDetails = async (id) => {
        setLoading(true);
        // TODO: will replace this with a new endpoint that just fetchces a single rule config by id
        const result = await getRuleConfigs();
        setRuleConfig(result.find((config) => config.id === id));

        setLoading(false);
    }

    return (
        <>
            {loading && <Box
                textAlign="center"
                color="text-status-inactive"
                margin="xxl"
                padding="xxl"
            >
                <Spinner size='large' />
            </Box>}
            {!loading && <SpaceBetween
                direction='vertical'
                size='l'
            >

                <BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={[
                        { text: "Code Defender", href: "/" },
                        { text: "Repository Rules", href: "/repo_rules" },
                        { text: ruleConfig.url, href: `/repo_rules/${id}` }
                    ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate(ev.detail.href);
                    }}
                />

                <Box variant='h1'>
                    {ruleConfig.url}

                    {isOwner() && <Box float="right">
                        <SpaceBetween direction="horizontal" size="xs">
                            <Button onClick={() => window.location.hash = "#editRepo"}>Edit</Button>
                            <Button>Delete</Button>
                        </SpaceBetween>
                    </Box>}
                </Box>

                <Container
                    header={
                        <Header variant="h2">
                            Overview
                        </Header>
                    }
                >

                    <ColumnLayout
                        borders="vertical"
                        columns={4}
                        variant="text-grid"
                    >
                        <div><ValueWithLabel label="Repository owners">{ruleConfig.owners ? ruleConfig.owners.map(owner => owner.fullName).join(', ') : ""}</ValueWithLabel></div>
                        <div>

                            <SpaceBetween
                                direction='vertical'
                                size='xs'
                            >
                                <ValueWithLabel label="Created by">{ruleConfig.createdBy}</ValueWithLabel>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween
                                direction='vertical'
                                size='xs'
                            >
                                <ValueWithLabel label="Last updated by">{ruleConfig.lastUpdatedBy}</ValueWithLabel>
                            </SpaceBetween>
                        </div>
                        <div>
                            <SpaceBetween
                                direction='vertical'
                                size='xs'
                            >
                                <ValueWithLabel label="Last updated at">{timeAgo(ruleConfig.lastUpdated)}</ValueWithLabel>
                            </SpaceBetween>
                        </div>
                    </ColumnLayout>
                </Container>

                <ContributorsTab contributors={ruleConfig.additionalContributors} loading={loading} />
                <RepoRulesTab isOwner={isOwner} rules={ruleConfig.rules} loading={loading} />

            </SpaceBetween >}
        </>
    );
};
