//React
import React, { Component } from "react";
import { DataAccessService } from 'Core/api';

// Components
import ServiceAppLayout from 'Component/ServiceAppLayout.jsx';
import { OSDownloadDropDown } from '../components/OSDownloadDropDown/';

// AWS UI
import {
  Box,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  SpaceBetween,
  Spinner
} from '@amzn/awsui-components-react';

// Help Functions
import getMetricsForUser from '../helpers/getMetricsForUser';
import { personalRisk, totalRisks } from 'Helper/riskMetrics';
import { User } from "Core/user";

// Styles
import css from "Style/pages/service-home-page.scss";
import { ValueWithLabel } from "../components/value-with-label";

//Components
class ServiceHomePage extends Component {
  constructor(props) {
    super(props)
    this.client = new DataAccessService();
    this.state = {
      loggedInUser: (new User()).userId,
    }
  }

  render() {
    return (
      <ServiceAppLayout
        page="ServiceHomePage"
        content={
          <Content {...this.props} />
        }
        disableContentPaddings={true}
      />
    );
  }
}

// The content in the main content area of the App layout
class Content extends Component {
  constructor(props) {
    super(props)
    this.metricsSearch = new DataAccessService();
    const user = new User();
    this.state = {
      user: user.userId,
      header: '',
      inputValue: user.email || '',
      data: [],
      modalVisible: false,
      tokenPageIndex: 1,
      riskData: { riskList: [], risksAvailable: false },
    };
    this.setModalVisibility = this.setModalVisibility.bind(this);
  }

  async componentDidMount() {
    this.loadData(this.state.user);
  }

  async loadData(user) {
    let metricsResult;
    this.setState({ loading: true });
    try {
      metricsResult = await getMetricsForUser(this.metricsSearch, {}, user)
    } finally {
      if (metricsResult) {
        if (metricsResult.public) {
          this.setState({ riskData: { riskList: personalRisk(metricsResult.risks, user), risksAvailable: metricsResult.chain ? metricsResult.chain.length > 0 : false } });
        }
      }
      this.setState({ loading: false });
    }
  }

  setModalVisibility(visibility) {
    this.setState({ modalVisible: visibility })
  }

  render() {
    return (
      <Box padding={{ bottom: 'xxl' }}>
        <div className="custom-home__header">
          <Box padding={{ vertical: 'xxxl', horizontal: 's' }}>
            <Grid
              gridDefinition={[
                { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
                { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
                { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
              ]}
            >
              <Box fontWeight="light" padding={{ top: 'xs' }}>
                <span className="custom-home__category">Global Services Security</span>
              </Box>
              <div className="custom-home__header-title">
                <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                  Code Defender
                </Box>
                <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
                  Protect Amazon and our customer's intellectual property
                </Box>
                <Box variant="p" fontWeight="light">
                  <span className="custom-home__header-sub-title">
                    Code Defender is desktop/server based application that protects Git repositories for our employees by installing a set of git hooks on a user's machine to carry out protective actions as users interact with git.
                  </span>
                </Box>
              </div>
              <div className="custom-home__header-cta">
                <Container
                  header={<Header
                    variant="h2"
                  >
                    Get started
                  </Header>}
                >
                  <OSDownloadDropDown />
                </Container>
              </div>
            </Grid>
          </Box>
        </div>

        <Box padding={{ top: 'xxxl', horizontal: 's' }}>
          <Grid
            gridDefinition={[
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 }, offset: { l: 2, xxs: 1 } },
              { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
            ]}
          >
            <SpaceBetween size="xxl">
              <div>
                <Container header={
                  <Header
                    variant="h2"
                    description="Updated once a day"
                  >
                    Your overview
                  </Header>
                }>
                  <ColumnLayout borders="vertical" columns={1}>
                    <ValueWithLabel label="Risk status">
                      {this.state.loading ? <Spinner size="large" /> : totalRisks(this.state.riskData)}
                    </ValueWithLabel>
                  </ColumnLayout>
                </Container>
              </div>
            </SpaceBetween>
            <div className="custom-home__sidebar">
              <SpaceBetween size="xxl">
                <Container
                  header={<Header>Helpful resources</Header>}
                >
                  <SpaceBetween direction="vertical" size="xs">
                    <Link
                      external
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/"
                    >
                      FAQ
                    </Link>
                    <Link
                      external
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserOnboarding/"
                    >
                      User onboarding
                    </Link>
                    <Link
                      external
                      href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/"
                    >
                      About Code Defender
                    </Link>
                  </SpaceBetween>
                </Container>
              </SpaceBetween>
            </div>
          </Grid>
        </Box>
      </Box>
    )
  }
}

export { Content }
export default ServiceHomePage;