import { FEATURE_FLAGS } from "../core/user/user";
import { LocalStorage } from "Core/storage";

export function isRulesEngineAccessible() {
    let repoManagementFlagEnabled = false;

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        repoManagementFlagEnabled = featureFlags['repo_mgmt'];
    }

    return repoManagementFlagEnabled;
}