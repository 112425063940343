import React from 'react';
import { Box, Button, Header, Modal, SpaceBetween, Table } from '@amzn/awsui-components-react';

export default function blockedCommitModal(setModalVisibility, data, user) {
  let modal;
  const personalColumnDefinitions = [
    {
      id: "metric",
      header: "Metric",
      cell: item => item.metric || "0"
    },
    {
      id: "oneDay",
      header: "One day",
      cell: item => item.oneDay || "0"
    },
    {
      id: "sevenDays",
      header: "Seven days",
      cell: item => item.sevenDays || "0"
    },
    {
      id: "thirtyDays",
      header: "Thirty days",
      cell: item => item.thirtyDays || "0"
    }
  ]

  modal = (<Modal
      onDismiss={() => {setModalVisibility(false)}}
      visible={true}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="primary" onClick={() => {setModalVisibility(false)}}>Ok</Button>
          </SpaceBetween>
        </Box>
      }
      header={<Header>Blocked Commit Breakdown for Reports of {user}</Header>}
    >
        <SpaceBetween direction="vertical" size="m">
            <p>
                For more details on these metrics and which metrics are tracked by Code Defender, 
                please visit <a href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#20">our help page</a>
            </p>

            <Table
                columnDefinitions={personalColumnDefinitions}
                items={data}
                empty={<div>No data</div>}
            />
        </SpaceBetween>
    </Modal>)

  return modal;
}