import { Navigate } from "react-router-dom";


import ServiceAppLayout from "../../components/ServiceAppLayout";
import { isDelegationAccessible } from "../../helpers/isDelegationAccessible";
import { Delegation } from "./Delegation";

export const DelegationPage = () => {
    return (
        <div>
            {(isDelegationAccessible()
                ?
                <ServiceAppLayout
                    content={
                        <Delegation />
                    }
                /> :
                <Navigate to="/" />)}

        </div>
    )
};
