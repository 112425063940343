import React, { useEffect, useState } from 'react';
import { Box, Button, ExpandableSection, Grid, Icon, SpaceBetween, Spinner, TextContent } from '@amzn/awsui-components-react';
import { identifyOS, OSType } from "../../helpers/identifyOS";
import { getDownloadLinks } from '../../helpers/getDownloadLinks';
import { User } from "../../core/user";
import { DataAccessService } from "../../core/api";
import { FlashbarPublisher } from '../FlashbarPublisher';
import "./OSDownloadDropDown.css";

export const OSDownloadDropDown = (props) => {
    const identifiedOS = identifyOS();
    const unidentifiableOS = identifiedOS === OSType.Unidentifiable;

    const [downloadOptions, setDownloadOptions] = useState([
        { name: OSType.Mac, links: [] },
        { name: OSType.Windows, links: [] },
        { name: OSType.RedHatLinux, links: [] },
        { name: OSType.DebianLinux, links: [] }
    ]);
    const [error, setError] = useState(false);
    const [buttonsDisabled, setButtonsDisabled] = useState(true);
    const [linksLoading, setLinksLoading] = useState(true);
    const [client] = useState(new DataAccessService({
        baseURL: `${window.location.protocol}//${window.location.host}`,
    }));

    // After initial render, load links for buttons
    useEffect(() => {
        const loadLinks = async () => {
            let result;
            try {
                const user = new User();
                result = await getDownloadLinks(client, user.userId, user.email);

                // Populate links to proper display option
                let optionsCopy = downloadOptions;
                optionsCopy[0].links.push(result.macArtifactUrl);
                optionsCopy[1].links.push(result.windowsArtifactUrl);
                optionsCopy[2].links.push(result.rpmArtifactUrl);
                optionsCopy[2].links.push(result.rpmArmArtifactUrl);
                optionsCopy[3].links.push(result.debianArtifactUrl);
                optionsCopy[3].links.push(result.debianArmArtifactUrl);
                setDownloadOptions(optionsCopy);
            } catch (error) {
                FlashbarPublisher.pushItem(
                    {
                        type: "error",
                        dismissible: true,
                        onDismiss: FlashbarPublisher.clear,
                        content: "There was an error fetching the download links. Please try again.",
                    }
                );
                setError(true);
            }

            setLinksLoading(false);
        }

        loadLinks();
    }, [])

    // Dependencies for buttons being disabled
    useEffect(() => {
        if (linksLoading || error) {
            setButtonsDisabled(true);
        } else {
            setButtonsDisabled(false);
        }
    }, [linksLoading, error])

    // Gather link(s) for auto-identified OS download button
    const isLinux = identifiedOS.search(/Linux/) !== -1;
    let identifiedLinks = [];
    if (isLinux) {
        identifiedLinks = identifiedOS.search(/Red Hat/) !== -1 ? downloadOptions[2].links : downloadOptions[3].links;
    } else if (identifiedOS === OSType.Mac) {
        identifiedLinks = downloadOptions[0].links;
    } else if (identifiedOS === OSType.Windows) {
        identifiedLinks = downloadOptions[1].links;
    }

    return linksLoading ? <Spinner size='large' />
        : <SpaceBetween size='s'>
            {
                unidentifiableOS ?
                    <Box className='unidentifiable_message' variant='p'>
                        Our system wasn't able to detect your device. Please see the list below to view all available installers.
                    </Box>
                    :
                    <div>
                        Download Code Defender for <b className='identified_os'>{identifiedOS}</b>
                    </div>
            }
            {
                isLinux ? <SpaceBetween size='xs'>
                    <Button className='identified_download_button' href={identifiedLinks[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download x86</Button>
                    <Button className='identified_download_button' href={identifiedLinks[1]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download ARM</Button>
                </SpaceBetween>
                    : (unidentifiableOS ? null
                        : <Button className='identified_download_button' href={identifiedLinks[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download</Button>)
            }
            <ExpandableSection
                data-testid="expandable-download-section"
                headerText={<small className="expandable-content-header">More download options</small>}
                variant="inline"
            >
                {downloadOptions.map((value, index) => {
                    return <div key={`download-row-${index}`}>
                        <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {value.name}:
                            </div>
                            <div style={{ float: 'right' }}>
                                {
                                    value.name.search(/Linux/) === -1 ? <Button className='download_button' href={value.links[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download</Button>
                                        : <SpaceBetween size='xs' alignItems="end">
                                            <Button className='download_button' href={value.links[0]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download x86</Button>
                                            <Button className='download_button' href={value.links[1]} target='_blank' disabled={buttonsDisabled} variant="primary" iconAlign="left" iconName="download">Download ARM</Button>
                                        </SpaceBetween>
                                }
                            </div>
                        </Grid>
                        <hr style={{ borderWidth: '1px' }} />
                    </div>
                })}
            </ExpandableSection>
        </SpaceBetween>
}