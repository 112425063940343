import { LocalStorage } from "../core/storage";
import { FEATURE_FLAGS } from "../core/user/user";

// Use the user's roles (obtained from the user-details service) to determine
// if new-dashboard access should be allowed.
export function isNewDashboardAccessible() {
    let newDashboardFlagEnabled = false;

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        newDashboardFlagEnabled = featureFlags['dashboard_ui'];
    }

    return newDashboardFlagEnabled;
}