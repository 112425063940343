import { LocalStorage } from "../core/storage";
import { FEATURE_FLAGS } from "../core/user/user";

export function isRuleManagementAccessible() {
    let ruleManagementFlagEnabled = false;

    const featureFlags = LocalStorage.get(FEATURE_FLAGS);
    if (featureFlags) {
        ruleManagementFlagEnabled = featureFlags['rule_scanning'];
    }

    return ruleManagementFlagEnabled;
}
