import { useState } from "react";

import {
    Box,
    Button,
    Form,
    FormField,
    Input,
    Modal,
    Select,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { getRepoAcknowledgementContent, RepoType } from "../../core/constants";
import { User } from "../../core/user";
import { getDelegationsFromRoles, isDelegate } from "../../helpers/delegation";
import { isAdmin } from "../../helpers/isAdmin";
import { addRepo } from "../../helpers/repos";
import { FlashbarPublisher } from "../FlashbarPublisher";


export const AddRepoModal = ({ isVisible, setIsVisible, refreshRepoList }) => {

    const user = new User();

    const createSelectOption = (value) => ({
        label: value,
        value
    });

    const getManagerAliasOptions = () => {
        const managerAliasOptions = [];
        if (isAdmin()) {
            managerAliasOptions.push(createSelectOption(user.userId));
        }
        if (isDelegate()) {
            managerAliasOptions.push(...getDelegationsFromRoles().map(delegation => createSelectOption(delegation?.managerAlias)));
        }
        return managerAliasOptions;
    }

    const [urlValue, setUrlValue] = useState("");
    const [managerValue, setManagerValue] = useState(getManagerAliasOptions()[0]);
    const [urlErrorText, setUrlErrorText] = useState("");
    const [managerErrorText, setManagerErrorText] = useState("");
    const [typeErrorText, setTypeErrorText] = useState("");
    const [selectedRepoType, setSelectedRepoType] = useState();
    const [reason, setReason] = useState("");
    const [reasonErrorText, setReasonErrorText] = useState("");
    const [loading, setLoading] = useState(false);

    const urlRegex = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/;


    const resetFormAndHideModal = () => {
        setUrlValue("");
        setManagerValue(getManagerAliasOptions()[0]);
        setUrlErrorText("");
        setSelectedRepoType();
        setReason("");
        setManagerErrorText("");
        setReasonErrorText("");
        setIsVisible(false);
    }

    const addRepoUrl = async (url, managerAlias) => {
        // Preliminary client error checks
        let hasErrors = false;
        // Removes whitespace from both sides of a string
        url = url.trim();

        if (!urlRegex.test(url)) {
            setUrlErrorText("You have entered an invalid repo URL. To proceed, please enter a valid URL. Correct example: https://github.com/aws/aws-cli");
            hasErrors = true;
        }
        if (url === "") {
            setUrlErrorText("Url cannot be empty.");
            hasErrors = true;
        }
        if (!selectedRepoType) {
            setTypeErrorText("Please select a repository type.");
            hasErrors = true;
        }
        if (!managerAlias) {
            setManagerErrorText("Please select a manager.");
            hasErrors = true;
        }
        if (reason.length > 250) {
            setReasonErrorText("Reason character length must be less than or equal to 250.");
            hasErrors = true;
        }
        if (selectedRepoType?.value === RepoType.Other && reason === '') {
            setReasonErrorText("Please input a reason.");
            hasErrors = true;
        }

        if (hasErrors) {
            return;
        }

        try {
            setLoading(true);
            await addRepo(url, managerAlias, selectedRepoType.value, reason);
            resetFormAndHideModal();
            FlashbarPublisher.setItems([{
                id: "add_repo_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "Repository added successfully."
            }]);
            await refreshRepoList();
        } catch (error) {
            const httpStatus = error.response?.status;
            let errorMessage = '';
            switch (httpStatus) {
                case 401:
                    errorMessage = "You are not authorized to add repositories.";
                    break;
                case 409:
                    setUrlErrorText("This repo is already approved and listed in the allow list. No further action necessary.");
                    break;
                default:
                    errorMessage = "There was an error adding your repository.";
                    FlashbarPublisher.setItems([{
                        id: "add_repo_error",
                        type: "error",
                        dismissible: true,
                        dismissLabel: "Dismiss message",
                        onDismiss: FlashbarPublisher.clear,
                        content: errorMessage
                    }]);
                    resetFormAndHideModal();
            }
        }
        setLoading(false);
    };

    return (
        <Modal
            data-testid="add-repo-modal"
            onDismiss={() => { resetFormAndHideModal() }}
            visible={isVisible}
            header="Add repository"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button
                            data-testid={"cancel-add-repo-modal-button"}
                            variant="link"
                            onClick={() => { resetFormAndHideModal() }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"add-repo-modal-button"}
                            variant="primary"
                            onClick={async () => { await addRepoUrl(urlValue, managerValue?.value) }}
                            loading={loading}
                        >
                            Acknowledge and add
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <form onSubmit={event => event.preventDefault()}>
                <Form>
                    <SpaceBetween size="l" direction="vertical">
                        <FormField
                            data-testid={"add-repo-url-form-field"}
                            label="Git remote repository URL"
                            errorText={urlErrorText}
                            description="Code Defender only supports the HTTPS protocol at this time.
"
                        >
                            <Input
                                onChange={({ detail }) => {
                                    setUrlValue(detail.value);
                                    setUrlErrorText("");
                                }}
                                value={urlValue}
                                placeholder="e.g. https://github.com/aws/aws-cli"
                                type="url"
                                inputMode="url"
                            />
                        </FormField>
                        <FormField
                            data-testid={"add-repo-type-form-field"}
                            label="Repository type"
                            errorText={typeErrorText}
                        >
                            <Select
                                selectedOption={selectedRepoType}
                                onChange={({ detail }) => {
                                    setSelectedRepoType(detail.selectedOption);
                                    setTypeErrorText("");
                                    setReasonErrorText("");
                                }
                                }
                                options={[
                                    { label: "Open Source Simple Contribution", value: RepoType.OpenSourceSimpleContribution },
                                    { label: "Customer Contract", value: RepoType.CustomerContract },
                                    { label: "Other", value: RepoType.Other },
                                ]}
                            />
                        </FormField>
                        <FormField
                            data-testid={"add-repo-reason-form-field"}
                            label={`Reason${selectedRepoType?.value === RepoType.Other ? '' : ' (optional)'}`}
                            errorText={reasonErrorText}
                        >
                            <Input
                                onChange={({ detail }) => {
                                    setReason(detail.value);
                                    setReasonErrorText("");
                                }}
                                value={reason}
                                type="text"
                            />
                        </FormField>
                        <FormField
                            data-testid={"add-repo-manager-form-field"}
                            label="Manager"
                            errorText={managerErrorText}
                        >
                            <Select
                                onChange={({ detail }) => {
                                    setManagerValue(detail.selectedOption);
                                    setManagerErrorText("");
                                }}
                                selectedOption={managerValue}
                                options={getManagerAliasOptions()}
                            />
                        </FormField>
                        <Box>
                            {selectedRepoType ? getRepoAcknowledgementContent(selectedRepoType.value, false) : ""}
                        </Box>
                    </SpaceBetween>
                </Form>
            </form>
        </Modal>
    );
};
