import { TopNavigation } from '@amzn/awsui-components-react';
import { User } from 'Core/user';
import React, { useEffect, useState } from 'react';
import AWSLogo from "../../media/images/aws.png";

import '../../styles/components/service-header.scss';
import getBypasses from '../helpers/getBypasses';
import { isTopRopeFeatureOn } from '../helpers/isTopRopeFlagOn';

const ServiceHeader = (props) => {
    const [user, setUser] = useState(new User());
    const [notifications, setNotifications] = useState([])

    const showBypassesNotification = async () => {
        try {
            const result = await getBypasses();
            const count = result.filter(bypass => !bypass.noVerifyReasonType).length;
            if (count > 0) {
                setNotifications([{
                    type: 'action',
                    text: `📣 You have ${count} unacknowledged bypass${count > 1 ? 'es' : ''} pending resolution. Click here to resolve.`,
                    id: "unacknowledged_bypasses_notification",
                    href: '/bypasses'
                }])
            }
        } catch (e) {
            // Fail silently
            console.error("Failed to get bypasses");
        }
    }
    useEffect(() => {
        if (isTopRopeFeatureOn()) {
            showBypassesNotification();
        }
    }, []);

    return (
        <header id="header" className="custom-main-header">
            <TopNavigation
                identity={{
                    href: '/',
                    logo: {
                        src: AWSLogo,
                        alt: 'Service'
                    }
                }}
                utilities={[
                    {
                        type: 'menu-dropdown',
                        iconName: "notification",
                        title: `Notifications (${notifications.length})`,
                        ariaLabel: "Notifications",
                        badge: notifications.length > 0,
                        disableUtilityCollapse: false,
                        items: notifications
                    },
                    {
                        type: 'menu-dropdown',
                        text: user.full_name,
                        description: user.email,
                        iconName: 'user-profile',
                        items: [
                            { id: 'profile', text: 'Profile', href: `https://phonetool.amazon.com/users/${user.userId}` }
                        ]
                    }
                ]}
            />
        </header>)
}

export default ServiceHeader;
