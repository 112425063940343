import { useState } from "react";

import { DataAccessService } from "../../../core/api";
import { deleteDelegation } from "../../../helpers/delegation";

import {
    Box,
    Button,
    Modal,
    SpaceBetween,
    TextContent
} from "@amzn/awsui-components-react";
import { FlashbarPublisher } from "../../FlashbarPublisher";

export const DeleteDelegateModal = ({
    isVisible,
    setIsVisible,
    clearSelectedItems,
    delegates,
    fetchDelegations
}) => {
    const [isLoading, setIsLoading] = useState(false);

    const deleteDelegates = async (delegates) => {
        try {
            setIsLoading(true);
            const client = new DataAccessService();
            await deleteDelegation(
                client,
                delegates.map((delegate) => { return { "alias": delegate["Alias"] } }),
                {},
                `${window.location.protocol}//${window.location.host}/delegations`
            );
            FlashbarPublisher.setItems([{
                id: "delete_delegation_success",
                type: "success",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: `${delegates.length > 1 ? "Delegates" : "Delegates"} deleted successfully.`
            }]);
            setIsLoading(false);
            clearSelectedItems();
            setIsVisible(false);
            await fetchDelegations();
        } catch (error) {
            const unprocessedItems = error.response?.data || [];
            let content = unprocessedItems.length > 0
                ? `There was an error removing the following Delegates: ${unprocessedItems.join(", ")}.`
                : `There was an error removing your Delegate(s).`;
            FlashbarPublisher.setItems([{
                id: "delete_delegation_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: content
            }]);
            setIsLoading(false);
            clearSelectedItems();
            setIsVisible(false);
            await fetchDelegations();
        }
    };

    return (
        <Modal
            data-testid={"delete-delegate-modal"}
            onDismiss={() => { setIsVisible(false) }}
            visible={isVisible}
            header={`Delete ${delegates.length > 1 ? "delegations" : "delegation"}`}
            footer={
                <Box float="right">
                    <SpaceBetween direction={"horizontal"} size={"xs"}>
                        <Button
                            data-testid={"cancel-delete-delegate-modal-button"}
                            variant={"link"}
                            onClick={() => { setIsVisible(false) }}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-testid={"delete-delegate-modal-button"}
                            variant={"primary"}
                            loading={isLoading}
                            loadingText={"Processing delete delegate(s) request."}
                            onClick={async () => { await deleteDelegates(delegates) }}
                        >
                            Delete
                        </Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <TextContent>
                <p>
                    {`Delete ${delegates.length} ${delegates.length > 1 ? "delegations" : "delegation"}: `}
                    <strong>{`${delegates.map((delegate) => delegate["DisplayName"]).join(", ")}`}</strong>?
                </p>
                <p>{`This action cannot be reversed.`}</p>
            </TextContent>
        </Modal>
    );
}
