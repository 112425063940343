import { useCollection } from "@amzn/awsui-collection-hooks";
import Box from "@amzn/awsui-components-react/polaris/box";
import Button from "@amzn/awsui-components-react/polaris/button";
import Header from "@amzn/awsui-components-react/polaris/header";
import Pagination from "@amzn/awsui-components-react/polaris/pagination";
import Table from "@amzn/awsui-components-react/polaris/table";
import TextFilter from "@amzn/awsui-components-react/polaris/text-filter";
import { useEffect, useState } from "react";
import { getMatchesCountText } from "../../../helpers/getMatchesCountText";
import { getRules } from "../../../helpers/rules/getRules";
import { getRenderedName } from "../../../helpers/rules/utils";
import { FlashbarPublisher } from "../../FlashbarPublisher";

export const RulePicker = ({ selectedRules, setSelectedRules, existingRules }) => {
    const [loading, setLoading] = useState(true);
    const [rules, setRules] = useState([]);

    const name = "name";
    const description = "description";
    const categories = "categories";

    const fetchRules = async () => {
        try {
            setLoading(true);
            const result = await getRules();
            setRules(result.filter(rule => !existingRules?.some(existingRule => existingRule.id === rule.id)));
        } catch (e) {
            FlashbarPublisher.setItems([{
                id: "fetch_rules_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error fetching your rule library."
            }]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchRules();
    }, []);

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        rules,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [name, description, categories],
            },
            sorting: {},
            selection: {}
        }
    );

    return (
        <Table
            {...collectionProps}
            renderAriaLive={({
                firstIndex,
                lastIndex,
                totalItemsCount
            }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            onSelectionChange={({ detail }) =>
                setSelectedRules(detail.selectedItems)
            }
            selectedItems={selectedRules}
            ariaLabels={{
                selectionGroupLabel: "Items selection",
                allItemsSelectionLabel: ({ selectedItems }) =>
                    `${selectedItems.length} ${selectedItems.length === 1 ? "item" : "items"
                    } selected`,
                itemSelectionLabel: ({ selectedItems }, item) =>
                    item.name
            }}
            columnDefinitions={[
                {
                    id: name,
                    header: "Rule name",
                    cell: item => getRenderedName(item),
                    sortingField: name,
                    isRowHeader: true
                },
                {
                    id: description,
                    header: "Description",
                    cell: item => item.description,
                    sortingField: description,
                },
                {
                    id: categories,
                    header: "Categories",
                    cell: item => item.categories.join(", "),
                    sortingField: categories
                }
            ]}
            enableKeyboardNavigation
            items={items}
            loading={loading}
            loadingText="Loading rule library"
            selectionType="multi"
            trackBy="name"
            onRowClick={(rule, index) => {

            }}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <b>No rules</b>
                </Box>
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search rules"
                />
            }
            header={
                <Header
                    counter={`(${selectedRules.length}/${items.length})`}
                    description="Code Defender rule library provides rules that scans for common patterns."
                    actions={
                        <Button
                            href="https://example.com"
                            iconName="external"
                            target="_blank">
                            Request new rule
                        </Button>
                    }
                >
                    Choose from library
                </Header>
            }
            pagination={
                < Pagination
                    {...paginationProps}
                    disabled={loading}

                />
            }
        />
    );
}