import { Link } from "@amzn/awsui-components-react";

/**
* Creates a user alias or display name hyperlinked to Phone Tool.
* @constructor
* @param {string} alias - The alias of the user.
* @param {string} [displayName] - The display (more human-readable) name of the user.
* @returns {JSX.Element}
*/
export const PhoneToolHyperlink = ({ alias, displayName }) => {
    return (
        <Link external href={`https://phonetool.amazon.com/users/${alias}`}>
            {displayName ?? alias}
        </Link>
    );
};
