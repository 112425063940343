import React from 'react';
import { useNavigate } from 'react-router-dom';

// Polaris
import { BreadcrumbGroup } from '@amzn/awsui-components-react';

const BreadCrumbController = {
  PageNotFound: [
    {
      text: 'Defender',
      href: '/'
    },
    {
      text: 'Page Not Found',
      href: '/404'
    }
  ]
};

const ServiceBreadcrumbs = ({ breadcrumbItems }) => {
  const navigate = useNavigate();

  const onFollowHandler = (ev) => {
    ev.preventDefault();
    if (ev.detail.href) {
      navigate(ev.detail.href);
    }
  };

  var crumbs = (this.props.page in BreadCrumbController) ? BreadCrumbController[this.props.page] : [];

  if (breadcrumbItems) {
    crumbs = crumbs.concat(breadcrumbItems);
  }

  return (
    <BreadcrumbGroup label="Breadcrumbs"
      onFollow={onFollowHandler.bind(this)}
      items={crumbs}
    />
  );

}

export { BreadCrumbController };
export default ServiceBreadcrumbs;