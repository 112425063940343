export function formatRiskGraphData(riskCounts) {
    let overallData = [], personalData = []
    if (riskCounts != null) {
        if (riskCounts.total.aggregated && hasDisplayableData(riskCounts.total.aggregated)) {
            var criticalDataset = formatData(riskCounts.critical.aggregated)
            var highDataset = formatData(riskCounts.high.aggregated)
            var mediumDataset = formatData(riskCounts.medium.aggregated)
            var lowDataset = formatData(riskCounts.low.aggregated)
            var totalDataset = formatData(riskCounts.total.aggregated)

            overallData = [
                {
                    title: "Critical Risks",
                    type: "line",
                    data: criticalDataset,
                },
                {
                    title: "High Risks",
                    type: "line",
                    data: highDataset,
                },
                {
                    title: "Medium Risks",
                    type: "line",
                    data: mediumDataset,
                },
                {
                    title: "Low Risks",
                    type: "line",
                    data: lowDataset,
                },
                {
                    title: "Total Risks",
                    type: "line",
                    data: totalDataset,
                },
            ]
        }
        if (riskCounts.total.personal && hasDisplayableData(riskCounts.total.personal)) {
            var criticalDataset = formatData(riskCounts.critical.personal)
            var highDataset = formatData(riskCounts.high.personal)
            var mediumDataset = formatData(riskCounts.medium.personal)
            var lowDataset = formatData(riskCounts.low.personal)
            var totalDataset = formatData(riskCounts.total.personal)

            personalData = [
                {
                    title: "Critical Risks",
                    type: "line",
                    data: criticalDataset,
                },
                {
                    title: "High Risks",
                    type: "line",
                    data: highDataset,
                },
                {
                    title: "Medium Risks",
                    type: "line",
                    data: mediumDataset,
                },
                {
                    title: "Low Risks",
                    type: "line",
                    data: lowDataset,
                },
                {
                    title: "Total Risks",
                    type: "line",
                    data: totalDataset,
                },
            ]
        }
    }

    return {
        overallData: overallData,
        personalData: personalData,
    }
}

function formatData(dataset) {
    return dataset.map(function(elt) {
        return { x : new Date(elt.date), y : elt.count }
    }).sort((a, b) => {return a.x - b.x});
}

function hasDisplayableData(dataset) {
    for (const date of dataset) {
        if (date.count != 0) {
            return true
        }
    }

    return false
} 

