import { BreadcrumbGroup, Header, Link, SpaceBetween } from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getBypasses from "../../helpers/getBypasses";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { NewBypassesTable } from "./NewBypassesTable";
import { BypassHistoryTable } from "./BypassHistoryTable";
import { NoVerifyReasonType } from "../../core/constants";

export const Bypasses = () => {
    const [newBypasses, setNewBypasses] = useState([]);
    const [oldBypasses, setOldBypasses] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();

    const fetchBypasses = async () => {
        try {
            setLoading(true);
            const result = await getBypasses() || [];
            setNewBypasses(result.filter(bypass => !bypass.noVerifyReasonType));
            setOldBypasses(result.filter(bypass => bypass.noVerifyReasonType));

            if (result.some(bypass => bypass.noVerifyReasonType === NoVerifyReasonType.Unintentional)) {
                FlashbarPublisher.pushItem({
                    header: "Having unintentional bypasses is extremely risky behavior, as your commits may contain sensitive information like secret keys or access IDs.",
                    type: "warning",
                    content:
                        <div>
                            If the bypass was unintentional, visit the{" "}
                            <Link
                                external
                                href="https://example.com/"
                                variant="primary"
                            >
                                troubleshooting guide
                            </Link>
                            {" "}to follow steps to identify the issue and prevent this from happening in the future.
                            {" "}If you cannot resolve the issue and still triggers bypass alert next month, it will be flagged as a potential compliance issue and escalate to your manger.
                        </div>,
                    id: "unintentional-bypass-flashbar"
                });
            }
        } catch (e) {
            console.error(e);
            FlashbarPublisher.setItems([{
                id: "fetch_bypasses_error",
                type: "error",
                dismissible: true,
                dismissLabel: "Dismiss message",
                onDismiss: FlashbarPublisher.clear,
                content: "There was an error fetching your bypasses."
            }]);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchBypasses();
    }, []);

    return (
        <div data-testid={"bypasses"}>
            <SpaceBetween direction='vertical' size='s'>
                < BreadcrumbGroup
                    test-id="breadcrumb-group"
                    items={
                        [
                            { text: "Code Defender", href: "/" },
                            { text: "My bypasses", href: "/bypasses" }
                        ]}
                    ariaLabel="Breadcrumbs"
                    onClick={(ev) => {
                        ev.preventDefault();
                        navigate(ev.detail.href);
                    }}
                />

                < Header
                    variant="h1"
                    description={<>
                        Updates daily and shows bypasses from the last 90 days. Bypasses are commits made with the <code> --no-verify</code > flag, which skips Code Defender's security hooks.{' '}
                        < Link
                            external
                            href="https://example.com/"
                            variant="primary"
                        >
                            Learn more about bypasses
                        </Link >
                    </>}
                >
                    My bypasses
                </Header >
                <div data-testid={"my-bypasses"}>
                    <SpaceBetween size='xxl' direction='vertical'>
                        {newBypasses.length > 0 &&
                            <div data-testid={"my-bypasses-table"}>
                                <NewBypassesTable
                                    bypasses={newBypasses}
                                    loading={loading}
                                    refresh={fetchBypasses}
                                />
                            </div>
                        }
                        <div data-testid={"bypass-history-table"}>
                            <BypassHistoryTable
                                bypasses={oldBypasses}
                                loading={loading}
                                refresh={fetchBypasses}
                            />
                        </div>
                    </SpaceBetween>
                </div>
            </SpaceBetween >
        </div >
    );
}