import React from 'react'
import {SpaceBetween, StatusIndicator} from '@amzn/awsui-components-react';

export default function installationStatus(successfulInstalls, missingInstalls, issueWithInstall, inReporting) {
    let installStatus = (<SpaceBetween direction="horizontal" size="s">
        <StatusIndicator type="error">
            Not Installed
        </StatusIndicator>
        <div style={{ minWidth: "80px" }}><strong>If you installed Code Defender today, your status will change tomorrow.</strong></div>
    </SpaceBetween>);

    if (missingInstalls === 0) {
        if (issueWithInstall !== 0) {
            installStatus = (<SpaceBetween direction="vertical" size="s">
                <StatusIndicator type="warning">
                    Issue with Install
                </StatusIndicator>
                <div style={{ minWidth: "80px" }}>Please follow the instructions for failing an installation check in from the <a href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#27">FAQ page</a>.</div>
            </SpaceBetween>);
        }
        else if (successfulInstalls > 0) {
            installStatus = (
                <StatusIndicator type="success">
                    Successfully Installed
                </StatusIndicator>);
        }
        else if (!inReporting) {
            installStatus = (<SpaceBetween direction="vertical" size="s">
                <StatusIndicator type="warning">
                    No Status
                </StatusIndicator>
                <div style={{ minWidth: "80px" }}>Your team is not set up to use Code Defender. If your team would like to use Code Defender, or you believe this is a mistake, <a href="https://w.amazon.com/bin/view/AWS/Teams/GlobalServicesSecurity/Engineering/CodeDefender/UserHelp/#99">please contact our team.</a></div>
            </SpaceBetween>);
        }
    }
    
    return installStatus;
} 