import { useEffect, useState } from "react";
import { useLocation, useParams } from 'react-router-dom';
import ServiceAppLayout from "../../components/ServiceAppLayout";
import { RepoDetail } from "../../components/repo-rules-ui/detail-ui/RepoDetail";
import { AddRules } from "../../components/team-rules-ui/add-ui/AddRules";
import { EditRepo } from "../../components/repo-rules-ui/modify-ui/EditRepo";
import { isRepoOwner } from "../../helpers/rules/utils";

export const RepoDetailPage = () => {
    const location = useLocation();
    const [showAddPage, setShowAddPage] = useState(false);
    const [showEditPage, setShowEditPage] = useState(false);
    const [ruleConfig, setRuleConfig] = useState({});
    const { id } = useParams();

    useEffect(() => {
        setShowAddPage(location.hash === '#addRules' && isRepoOwner(ruleConfig));
        setShowEditPage(location.hash === "#editRepo" && isRepoOwner(ruleConfig));
    }, [location.hash])

    return (
        <div>
            {
                <ServiceAppLayout
                    content={
                        <>
                            {showAddPage && <AddRules ruleConfig={ruleConfig} />}
                            {showEditPage && <EditRepo ruleConfig={ruleConfig} />}
                            {!showAddPage && !showEditPage && <RepoDetail id={id} ruleConfig={ruleConfig} setRuleConfig={setRuleConfig} />}
                        </>
                    }
                />
            }
        </div>
    )
};
