// Polaris
import { Link } from '@amzn/awsui-components-react';
import { useNavigate } from 'react-router-dom';

export const InternalLink = (props) => {
    const navigate = useNavigate();
    const onFollowHandler = (ev) => {
        ev.preventDefault();
        if (ev.detail.href) {
            console.log(ev.detail.href);
            navigate(ev.detail.href);
        }
    };

    return (<Link color={props.color} href={props.href} variant={props.variant} onFollow={onFollowHandler}>{props.children}</Link>)
};