import { Navigate } from "react-router-dom";
import ServiceAppLayout from "../../components/ServiceAppLayout";
import { isRulesEngineAccessible } from "../../helpers/isRulesEngineAccessible";
import { RulesEngine } from "./RulesEngine";

export const RulesEnginePage = () => {
    return (
        <div>
            {
                ((isRulesEngineAccessible())
                    ?
                    <ServiceAppLayout
                        content={
                            <RulesEngine />
                        }
                    /> :
                    <Navigate to="/" />)
            }
        </div>
    )
};
