/*
    This module is intended to be used as a low-level client for making ajax requests.
    The intention of this module is to create an axios instance, add in common defaults that
    all requests will need such as the Authentication header and return it. Think of
    this module as a factory module that creates standardized axios instances.

    This module is mostly intended to be used by the data access service module. Most devs will
    never need to touch this module.

    Since this module returns an axios instance, all the normal methods available on the axios object
    will be available on this module as well:
    https://github.com/axios/axios#axios-api

    The main benifit of this module is that every request will grab the idToken from the cookies and insert it into the
    Authentication header. If the cookie is not found, an error is thrown.

    A lot of this is borrowed from:
    https://github.com/axios/axios/issues/320#issuecomment-219474269

    Usage of this module looks like:
    ```
        import {AjaxService} from 'ore/api'

        AjaxService.get('/users')
            .then(response => {})
            .catch(err => {})

        AjaxService.post('/user', {
            firstName: 'Fred',
            lastName: 'Flintstone'
        })
        .then(function (response) {
            console.log(response);
        })
        .catch(function (error) {
            console.log(error);
        });

        // You can also await this module too
        async function getUsers() {
            try {
                const response = await AjaxService.get('/users');
            } catch (error) {
                console.error(error);
            }
        }
    ```
*/
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { transferToLogin } from '../../../helpers/transferToLogin';

const requestInterceptor = async (config) => {
    let auth;
    let authErr;

    try {
        auth = await Auth.currentSession();
    } catch (err) {
        authErr = err;
    }

    if (!auth) {
        transferToLogin();
        if (authErr) {
            throw authErr;
        }
        return;
    }

    let configuration = { ...config };
    if (auth.idToken?.jwtToken) configuration = {
        ...config,
        headers: { ...config.headers, Authorization: auth.idToken.jwtToken }
    };
    return configuration;

}

const instance = axios.create();
instance.interceptors.request.use(requestInterceptor);

export default instance