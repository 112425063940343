/*
    The intended use for this function is to provide a base URL in the client object being passed in
    If base URL provided, no URL parameter is necessary
    Only overwrite the default URL in the event that you are providing a whole URL
    
    standard example:
        client = new DataAccessService({ baseURL: "https://" + window.location.host });
        result = await getMetricsForUser(this.client, {}, { username: user })

    overwrite URL example
        client = new DataAccessService();
        result = await getMetricsForUser(this.client, {}, { username: user }, "https://" + window.location.host + '/analytics')
*/

export default async function getMetricsForUser(client, data, username, url = '/analytics') {
        try {
            return await client.post(url, data, {username});
         }
            catch(err){
                console.warn(err);
                throw err;
            }
        }
  