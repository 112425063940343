import { User } from '../core/user';
import { isAvpFlagOn } from "./isAvpFlagOn";

const ADMIN_ROLE = 'Admin';
const DELEGATE_ROLE_PREFIX = 'Delegate{';

export const hasAdminLikeRole = () => {

    let hasAdminLikeRole = false;   
    const avpFlagOn = isAvpFlagOn();
    const user = new User();
     if (avpFlagOn) {        
        let isDelegate = user.is_delegate; 
        let isManager = user.is_manager;      
        hasAdminLikeRole = isManager || isDelegate;
     } else {   
         for (let role of user.roles) {
             if (role === ADMIN_ROLE) {
                 hasAdminLikeRole = true;
             } else if (role.startsWith(DELEGATE_ROLE_PREFIX)) {
                 hasAdminLikeRole = true;
             }
         }
     }
     return hasAdminLikeRole;
}