import { Box } from "@amzn/awsui-components-react";
import { PhoneToolHyperlink } from "../phone-tool-hyperlink";

export const columnDefinitions = [
    {
        id: "name",
        header: "Name",
        cell: item => <PhoneToolHyperlink alias={item["Alias"]} displayName={item["DisplayName"]} />,
        sortingField: "DisplayName"
    },
    {
        id: "alias",
        header: "Alias",
        cell: item => item["Alias"],
        sortingField: "Alias"
    },
    {
        id: "jobTitle",
        header: "Job title",
        cell: item => item["BusinessTitle"],
        sortingField: "BusinessTitle"
    }
];

export const EmptyState = (props) => {
    return (
        <Box
            margin={{ vertical: "xs" }}
            textAlign="center"
            color="inherit"
        >
            {props.children}
        </Box>
    );
};
