import { Select } from "@amzn/awsui-components-react";
import Box from "@amzn/awsui-components-react/polaris/box";
import Header from "@amzn/awsui-components-react/polaris/header";
import Table from "@amzn/awsui-components-react/polaris/table";
import { getEnforcementTypeSelectOptions, getRenderedEnforcementType, getRenderedName } from "../../../helpers/rules/utils";

export const EnforcementPicker = ({ selectedRules, ruleEnforcements, setRuleEnforcements }) => {
    return (
        <Table
            trackBy={"id"}
            renderAriaLive={({
                firstIndex,
                lastIndex,
                totalItemsCount
            }) =>
                `Displaying items ${firstIndex} to ${lastIndex} of ${totalItemsCount}`
            }
            ariaLabels={{
                activateEditLabel: (column, item) =>
                    `Edit ${item.name} ${column.header}`,
                cancelEditLabel: column =>
                    `Cancel editing ${column.header}`,
                submitEditLabel: column =>
                    `Submit editing ${column.header}`,
                tableLabel: "Choose enforcement types"
            }}
            columnDefinitions={[
                {
                    id: "name",
                    header: "Rule name",
                    cell: item => getRenderedName(item)
                },
                {
                    id: "commitEnforcement",
                    header: "Commit enforcement",
                    minWidth: 176,
                    editConfig: {
                        ariaLabel: "Commit enforcement",
                        editIconAriaLabel: "editable",
                        editingCell: (
                            item,
                            { currentValue, setValue }
                        ) => {
                            const value = currentValue ?? ruleEnforcements[item.id].commitEnforcement;
                            return (
                                <Select
                                    autoFocus={true}
                                    expandToViewport={true}
                                    selectedOption={
                                        getEnforcementTypeSelectOptions().find(
                                            option => option.value === value
                                        ) ?? null
                                    }
                                    onChange={event => {
                                        setValue(
                                            event.detail.selectedOption.value ??
                                            ruleEnforcements[item.id].commitEnforcement
                                        );
                                    }}
                                    options={getEnforcementTypeSelectOptions()}
                                />
                            );
                        }
                    },
                    cell: item => {
                        return getRenderedEnforcementType(ruleEnforcements[item.id]?.commitEnforcement);
                    }
                },
                {
                    id: "pushEnforcement",
                    header: "Push enforcement",
                    minWidth: 176,
                    editConfig: {
                        ariaLabel: "Push enforcement",
                        editIconAriaLabel: "editable",
                        editingCell: (
                            item,
                            { currentValue, setValue }
                        ) => {
                            const value = currentValue ?? ruleEnforcements[item.id].pushEnforcement;
                            return (
                                <Select
                                    autoFocus={true}
                                    expandToViewport={true}
                                    selectedOption={
                                        getEnforcementTypeSelectOptions().find(
                                            option => option.value === value
                                        ) ?? null
                                    }
                                    onChange={event => {
                                        setValue(
                                            event.detail.selectedOption.value ??
                                            ruleEnforcements[item.id].pushEnforcement
                                        );
                                    }}
                                    options={getEnforcementTypeSelectOptions()}
                                />
                            );
                        }
                    },
                    cell: item => {
                        return getRenderedEnforcementType(ruleEnforcements[item.id]?.pushEnforcement);
                    }
                }
            ]}
            enableKeyboardNavigation
            items={selectedRules}
            submitEdit={async (item, column, newValue) => {
                await new Promise((resolve, reject) => {
                    ruleEnforcements[item.id][column.id] = newValue;
                    resolve();
                });
            }}
            empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <b>No rules selected</b>
                </Box>
            }
            header={<Header description="Commit stage is when builders add changes to their local repository. Push stage is when builders upload the last commit(s) to a remote server.">Choose enforcement types</Header>}
        />
    );
}