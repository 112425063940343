import { useState } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";

import {
    Button,
    Header,
    Table,
    SpaceBetween
} from "@amzn/awsui-components-react";
import { columnDefinitions, EmptyState } from "./table-config";
import { AddDelegateModal, DeleteDelegateModal } from "./modals";

export const DelegatedByUserTable= ({ tableItems, isLoading, fetchDelegations }) => {
    const [addModalIsVisible, setAddModalIsVisible] = useState(false);
    const [deleteModalIsVisible, setDeleteModalIsVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);

    const { items, collectionProps } = useCollection(
        tableItems,
        {
            filtering: {
                empty: (
                    <EmptyState>
                        <b>{"You have no delegates."}</b>
                    </EmptyState>
                )
            },
            sorting: {}
        }
    );

    return (
        <div data-testid={"delegated-by-user-table"}>
            <AddDelegateModal
                isVisible={addModalIsVisible}
                setIsVisible={(isVisible) => setAddModalIsVisible(isVisible)}
                delegateAliases={tableItems.map((item) => item["Alias"])}
                fetchDelegations={fetchDelegations}
            />
            <DeleteDelegateModal
                isVisible={deleteModalIsVisible}
                setIsVisible={(isVisible) => setDeleteModalIsVisible(isVisible)}
                clearSelectedItems={() => setSelectedItems([])}
                delegates={selectedItems.map((item) => {
                    return {
                        "Alias": item["Alias"],
                        "DisplayName": item["DisplayName"]
                    }
                })}
                fetchDelegations={fetchDelegations}
            />
            <Table
                {...collectionProps}
                header={
                    <Header
                        counter={
                            selectedItems.length
                                ? `(${selectedItems.length}/${items.length})`
                                : `(${items.length})`
                        }
                        actions={
                            <SpaceBetween direction={"horizontal"} size="xs">
                                <Button
                                    data-testid={"delete-delegate-table-button"}
                                    variant={"normal"}
                                    disabled={selectedItems.length === 0}
                                    onClick={() => { setDeleteModalIsVisible(true) }}
                                >
                                    Delete
                                </Button>
                                <Button
                                    data-testid={"add-delegate-table-button"}
                                    variant="primary"
                                    onClick={() => { setAddModalIsVisible(true) }}
                                >
                                    Add delegate
                                </Button>
                            </SpaceBetween>
                        }
                    >
                        {"Delegate to others"}
                    </Header>
                }
                items={items}
                columnDefinitions={columnDefinitions}
                loading={isLoading}
                loadingText={"Fetching your delegates"}
                selectedItems={selectedItems}
                selectionType={"multi"}
                trackBy={"Alias"}
                onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems) }
            />
        </div>
    );
};
