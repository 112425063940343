import { useEffect, useState } from "react";

import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Alert,
  Box,
  Button,
  Link,
  Pagination,
  SpaceBetween,
  Table,
  TextFilter,
} from "@amzn/awsui-components-react";
import getAllowedOrgs from "../../helpers/getAllowedOrgs";
import { getMatchesCountText } from "../../helpers/getMatchesCountText";

export const OrgAllowListTable = () => {
  const [loading, setLoading] = useState(true);
  const [orgs, setOrgs] = useState([]);

  const name = "org";
  const type = "orgType";

  const loadOrgs = async () => {
    setLoading(true);
    const orgs = await getAllowedOrgs();
    setOrgs(orgs);
    setLoading(false);
  };

  useEffect(() => {
    loadOrgs();
  }, []);

  // Table collection
  const {
    items,
    actions,
    collectionProps,
    filterProps,
    filteredItemsCount,
    paginationProps,
  } = useCollection(orgs, {
    pagination: {
      pageSize: 10,
    },
    // Controls which fields the search bar applies to
    // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
    filtering: {
      fields: [name, type],
    },
    sorting: {
      defaultState: {
        sortingColumn: {
          sortingField: name,
        },
        isDescending: true,
      },
    },
    selection: {},
  });

  return (
    <SpaceBetween direction="vertical" size="s">
      <Alert>
        GitHub organizations are provided by the Open Source Program Office
        (OSPO). A push to any repository in an approved organization is
        automatically allowed.
      </Alert>
      <Table
        {...collectionProps}
        variant="borderless"
        columnDefinitions={[
          {
            id: name,
            header: "Organization name",
            cell: (item) => (
              <Link href={`https://github.com/${item.org}`} target="_blank">
                {item.org}
              </Link>
            ),
            isRowHeader: true,
            sortingField: name,
          },
          {
            id: type,
            header: "Type",
            cell: (item) =>
              item.orgType
                ? item.orgType.replace(/([A-Z])/g, " $1").trim()
                : "-",
            sortingField: type,
          },
        ]}
        columnDisplay={[
          { id: name, visible: true },
          { id: type, visible: true },
        ]}
        items={items}
        loading={loading}
        loadingText="Loading organizations..."
        empty="No organizations found"
        filter={
          <TextFilter
            {...filterProps}
            countText={getMatchesCountText(filteredItemsCount)}
            filteringPlaceholder="Find organizations"
          />
        }
        pagination={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                iconName="refresh"
                variant="icon"
                onClick={() => {
                  loadOrgs();
                }}
              />
              <Pagination {...paginationProps} disabled={loading} />
            </SpaceBetween>
          </Box>
        }
      />
    </SpaceBetween>
  );
};
