import { DataAccessService } from "../../core/api";
import { User } from "../../core/user";
import getMetricsForUser from "../getMetricsForUser";

import { InstallationStatuses } from "./constants";

/**
 * Determines installation status of the user based on their metrics.
 *
 * Returns either the installation status itself, or an error. The error is intentionally not captured/handled
 * in this helper method. The error is to be bubbled up and handled by the caller.
 *
 * @returns {Promise<string>}
 */
export const getInstallationStatus = async () => {
    const client = new DataAccessService({
        baseURL: `${window.location.protocol}//${window.location.host}`
    });
    const user = new User();

    let successfulInstalls = 0;
    let missingInstalls = 0;
    let issueWithInstall = 0;
    let inReporting = false;

    const metrics = await getMetricsForUser(client, {}, user.userId);
    if (metrics && metrics.public) {
        successfulInstalls = metrics.public.thirty["installs"];
        missingInstalls = metrics.public.thirty["missing_installs"];
        issueWithInstall = metrics.public.thirty["issue_with_install"];
        inReporting = metrics.chain ? metrics.chain.length > 0 : false;
    }

    let installationStatus;
    if (missingInstalls === 0) {
        if (issueWithInstall !== 0) {
            installationStatus = InstallationStatuses.IssueWithInstallation;
        } else if (successfulInstalls > 0) {
            installationStatus = InstallationStatuses.SuccessfulInstallation;
        } else if (!inReporting) {
            installationStatus = InstallationStatuses.NoStatus;
        }
    } else {
        installationStatus = InstallationStatuses.NotInstalled;
    }

    return installationStatus;
};
