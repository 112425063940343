// Fill this object with the values found in the config.js file that is stored in your S3 Bucket for local development

let devWebConfig = {
    region: '', // Fill in from region in config.js
    userPoolId: '', // Fill in from userPoolId in config.js
    userPoolWebClientId: '', // Fill in from userPoolWebClientId in config.js
    domain: '',
    cognitoDomain: '', // Fill in from cognitoDomain in config.js
}

export default devWebConfig