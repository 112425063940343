import React from 'react';


class ErrorBoundary extends React.Component {
    state = { error: null, errorInfo: null };

    componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo
      });
    }

    render() {
      if (this.state.errorInfo) {
        return (
            <div className="awsui-grid awsui-util-p-s generic-not-found">
                <div className="generic-not-found__container custom-awsui-util-pt-xxxl awsui-row">
                    <div className="col-s-12">
                        <div className="generic-not-found__content col--12 align-center">
                            <div className="align-center color-primary">
                                <h1>A fatal error has occurred</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
      }

      return this.props.children;
    }

    
  }

export default ErrorBoundary;