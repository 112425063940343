// Polaris
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
    Box,
    CopyToClipboard,
    Header,
    Pagination,
    Popover,
    Table,
    TextFilter,
} from '@amzn/awsui-components-react';
import { getMatchesCountText } from '../../helpers/getMatchesCountText';
import daysAgo from "../../helpers/daysAgo";

export const BypassHistoryTable = ({ bypasses, loading }) => {

    // List of table ids, also used for defining which attribute on item to sort and filter
    const directory = "directory";
    const commitId = "commitId";
    const time = "time";
    const reason = "reason";
    const details = "details";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        bypasses,
        {
            pagination: {
                pageSize: 10,
            },
            // Controls which fields the search bar applies to
            // Cloudscape documentation for filtering and sorting: https://cloudscape.aws.dev/get-started/dev-guides/collection-hooks/
            filtering: {
                fields: [directory, commitId],
            },
            sorting: {
                defaultState: {
                    sortingColumn: {
                        sortingField: time
                    },
                    isDescending: true
                }
            },
            selection: {}
        }
    );

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    counter={`(${bypasses.length})`}
                >
                    Bypass history
                </Header>
            }
            items={items}
            loading={loading}
            loadingText='Loading bypass history...'
            stickyColumns={{ first: 4, last: 0 }}
            columnDefinitions={
                [
                    {
                        id: directory,
                        header: "Directory",
                        cell: item => item.directory,
                        sortingField: directory
                    },
                    {
                        id: commitId,
                        header: "Commit ID",
                        cell: item => {
                            return item.commitId ? <CopyToClipboard
                                copyButtonAriaLabel="Copy Commit ID"
                                copyErrorText="Commit ID failed to copy"
                                copySuccessText="Commit ID copied"
                                textToCopy={item.commitId}
                                variant="inline"
                            />
                                :
                                <>-</>
                        },
                        sortingField: commitId
                    },
                    {
                        id: time,
                        header: "Commit date",
                        cell: item => <Popover
                            dismissButton={false}
                            position="top"
                            size="small"
                            content={new Date(item.time).toDateString()}
                        >
                            {daysAgo(item.time)}
                        </Popover>,
                        sortingField: time
                    },
                    {
                        id: reason,
                        header: "Reason",
                        cell: item => item.noVerifyReasonType,
                        sortingField: reason
                    },
                    {
                        id: details,
                        header: "Details",
                        cell: item => item.noVerifyReasonDetails || "-",
                        sortingField: details
                    }
                ]
            }
            filter={
                <TextFilter
                    {...filterProps}
                    countText={getMatchesCountText(filteredItemsCount)}
                    filteringPlaceholder="Search by commit ID or repository directory"
                />
            }
            pagination={
                <Box float="right">
                    < Pagination
                        {...paginationProps}
                        disabled={loading}

                    />
                </Box>
            }
            empty="No past bypasses found 🎉"

        />);
}
