import React from 'react'
import { 
    Badge, 
    Box, 
    Button, 
    Header, 
    Icon, 
    Link, 
    Modal, 
    Pagination, 
    SpaceBetween, 
    StatusIndicator, 
    Table, 
    TextContent 
} from '@amzn/awsui-components-react';

// Risk Contants

export const riskColumnDefinitions = ( setModalToRiskData, setModalVisibility ) => [
    {
      id: "metric",
      header: "Metric",
      minWidth: 210,
      cell: item => 
      <Button id={`${item.username}RiskInformation`} variant="link" onClick={() => 
        {
          setModalToRiskData(item);
          setModalVisibility(true);
        }}>
          {<Link>{item.title}</Link>}
      </Button> || "N/A"
    },
    {
      id: "priority",
      header: "Priority",
      width: 165,
      cell: item => priorityLevel(item.priority) || "N/A"
    },
    {
      id: "description",
      header: "Description",
      cell: item => item.description || "None"
    },
    {
      id: "inViolation",
      header: "In Violation",
      cell: item => <p><Icon name="user-profile"/> {item.inViolation.length}</p> || <p><Icon name="user-profile"/> 0</p>
    },
    {
      id: "personalStatus",
      header: "Your Status",
      cell: item => personalStatus(item.personalRisk) || "-"
    }
]

// Export functions

export function personalRisk(riskListInput, username) {
    let riskList = riskListInput ? riskListInput : []
    let sortingOrder = {
        'Critical': 1,
        'High': 2,
        'Medium': 3,
        'Low': 4,
        'None': 5,
        'N/A': 6,
        '': 7
    }

    riskList.sort(function (a, b) {
        const first = sortingOrder[a.priority]
	    const second = sortingOrder[b.priority]

        if (first > second) {
            return 1;
        }
        if (second > first) {
            return -1;
        }
        return 0;
    });

    for (let i = 0; i < riskList.length; i++) {
        riskList[i].personalRisk = false
        for (let j = 0; j < riskList[i].inViolation.length; j++) {
            if (riskList[i].inViolation[j] === username) {
                riskList[i].personalRisk = true
                riskList[i].inViolation[j] = [<div><Link href={`https://phonetool.amazon.com/users/${riskList[i].inViolation[j]}`}>{riskList[i].inViolation[j]}</Link>&nbsp;<Icon name="user-profile"/></div>]
            }
            else {
                riskList[i].inViolation[j] = [<Link href={`https://phonetool.amazon.com/users/${riskList[i].inViolation[j]}`}>{riskList[i].inViolation[j]}</Link>]
            }
        }
    }
    return riskList
}

export function totalRisks(riskData) {
    if (!riskData.risksAvailable) {
        return (
            <div>
                <StatusIndicator type="info">Cannot calculate risks</StatusIndicator>
            </div>)
    }
    else {
        let total = 0;
        let statusType = "success"
        let message = "No active risks";

        for (const risk of riskData.riskList) {
            if (risk.personalRisk) {
                total++
            }
        }

        if (total > 0) {
            statusType = "warning"
            message = total + (total > 1 ? " active risks" : " active risk")
        }

        return (
        <div>
            <StatusIndicator type={statusType}>{message}</StatusIndicator>
            <TextContent><small>In the last 30 days</small></TextContent>
        </div>)
    }
}

export function priorityLevel(level) {
    let priority = (<Badge color="green">None</Badge>);

    switch (level) {
        case 'Low':
            priority = (<Badge color="blue">Low</Badge>);
            break;
        case 'Medium':
            priority = (<Badge color="blue">Medium</Badge>);
            break;
        case 'High':
            priority = (<Badge color="red">High</Badge>);
            break;
        case 'Critical':
            priority = (<Badge color="red">Critical</Badge>);
            break;
    }
    
    return priority;
} 

export function personalStatus(personalRisk) {
    let personalStatus = "-";

    if (personalRisk) {
        personalStatus = (
            <StatusIndicator type="warning">In Violation</StatusIndicator>
        )
    }
    
    return personalStatus;
} 
