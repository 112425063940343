import React, { useState } from "react";

import ServiceAppLayout from "Component/ServiceAppLayout.jsx";
import { RegistrationDetails } from "./RegistrationDetails";
import { CustomHomeHeader } from "Component/custom-home-header/CustomHomeHeader.jsx";
import { Box, Flashbar } from "@amzn/awsui-components-react";

export const RegistrationDetailsPage = () => {
    const [ flashBarError, setFlashBarError ] = useState(false);

    return (
        <ServiceAppLayout
            page={"RegistrationDetailPage"}
            navigationHide={true}
            toolsHide={true}
            notifications={[flashBarError ?
                (
                    <div style={{ paddingTop: "68px"}}>
                        <Flashbar
                            items={[
                                {
                                    id: "token_error_message",
                                    type: "error",
                                    content: "There was an error fetching your registration token. Please try again."
                                }
                            ]}
                        />
                    </div>
                )
                : null
            ]}
            content={
                <div>
                    <Box padding={{ bottom: "xxl" }}>
                        <CustomHomeHeader
                            supertitle={"Professional Services"}
                            title={"Code Defender"}
                            subtitle={"Registration details"}
                            flashBarDisplayed={flashBarError}
                        />
                        <RegistrationDetails setFlashBarError={() => setFlashBarError(true)}/>
                    </Box>
                </div>
            }
        />
    );
};
