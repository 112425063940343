import { useCollection } from "@amzn/awsui-collection-hooks";
import { Box, Header, Pagination, SpaceBetween, Table } from "@amzn/awsui-components-react";

export const ContributorsTab = ({ contributors, loading }) => {
    // List of table ids, also used for defining which attribute on item to sort and filter
    const name = "name";
    const alias = "alias";

    // Table collection
    const { items, actions, collectionProps, filterProps, filteredItemsCount, paginationProps } = useCollection(
        contributors || [],
        {
            pagination: {
                pageSize: 10,
            },
            sorting: {},
            selection: {}
        }
    );

    return (
        <Table
            {...collectionProps}
            header={
                <Header
                    counter={`(${contributors ? contributors.length : 0})`}
                >
                    Additional contributors
                </Header>
            }
            items={items}
            loading={loading}
            loadingText='Loading contributors...'
            columnDefinitions={
                [
                    {
                        id: name,
                        header: "Name",
                        cell: item => item.fullName,
                        isRowHeader: true,
                        sortingField: name
                    },
                    {
                        id: alias,
                        header: "Alias",
                        cell: item => item.username,
                        sortingField: alias
                    }
                ]
            }
            pagination={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        < Pagination
                            {...paginationProps}
                            disabled={loading}

                        />
                    </SpaceBetween>
                </Box>
            }
            empty="No additional contributors"
        />);
}