import { DataAccessService } from "../../core/api";

export const RepoRequstStatus = {
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    PENDING: "PENDING"
}

export const sendRejectionRequests = async (requests, rejectionReason) => {
    const client = new DataAccessService({baseURL: `${window.location.protocol}//${window.location.host}`});
    let promises = [];
    let failedRequests = [];
    for (let i = 0; i < requests.length; i++) {
        promises.push(client.post('/reporequest', {requestId: requests[i].id, action: RepoRequstStatus.REJECTED, rejectionReason:rejectionReason}, {}).catch((err) => {
            failedRequests.push(requests[i]);
        }));
    }
    await Promise.all(promises).catch((err) => {});

    return failedRequests;
}

export const sendApprovalRequests = async (requests) => {
    const client = new DataAccessService({baseURL: `${window.location.protocol}//${window.location.host}`});
    let promises = [];
    let failedRequests = [];
    for (let i = 0; i < requests.length; i++) {
        promises.push(client.post('/reporequest', {requestId: requests[i].id, action: RepoRequstStatus.APPROVED}, {}).catch((err) => {
            failedRequests.push(requests[i]);
        }));
    }

    await Promise.all(promises).catch((err) => {});

    return failedRequests;
}

export const getRequests = async () => {
    const client = new DataAccessService({baseURL: `${window.location.protocol}//${window.location.host}`});
    return client.get('/reporequest', {}, {});
}