import { User } from '../../core/user';
import { isAvpFlagOn } from "../isAvpFlagOn";

const DELEGATE_ROLE_PREFIX = 'Delegate{';

export const isDelegate = () => {
    const user = new User();
    const avpFlagOn = isAvpFlagOn();
    if (avpFlagOn) {
        return user.is_delegate;
    } else {
        return user.roles.some(role => role.startsWith(DELEGATE_ROLE_PREFIX));
    }

}