import React from 'react'
import { 
    Box, 
    Button, 
    Header, 
    Modal, 
    Pagination, 
    SpaceBetween, 
    Table, 
} from '@amzn/awsui-components-react';
import { personalStatus, priorityLevel } from './riskMetrics'

// Risk Contants

const ValueWithLabel = ({ label, children }) => (
    <SpaceBetween direction="vertical" size="xxs">
      <Box variant="awsui-key-label">{label}</Box>
      <div>{children}</div>
    </SpaceBetween>
)

// Risk Modal

export default function riskModal(setModalVisibility, riskInfo, currentPageIndex, setCurrentPageIndex) {    
    let modal = (
    <Modal
        onDismiss={() => {setModalVisibility(false)}}
        visible={true}
        closeAriaLabel="Close modal"
        size="large"
        footer={
          <Box float="right">
                <SpaceBetween direction="horizontal" size="xs">
                    <Button variant="primary" onClick={() => {setModalVisibility(false)}}>Ok</Button>
                </SpaceBetween>
          </Box>
        }
        header={riskInfo.title}
      >
        <SpaceBetween direction="vertical" size="xxl">
            <ValueWithLabel label={<Header variant="h3">Description</Header>}>
                {riskInfo.description}
            </ValueWithLabel>
            <ValueWithLabel label={<Header variant="h3">Priority</Header>}>
                {priorityLevel(riskInfo.priority)}
            </ValueWithLabel>
            <ValueWithLabel label={<Header variant="h3">Mitigation</Header>}>
                {convertMarkdownToHyperlink(riskInfo.mitigation)}
            </ValueWithLabel>
            <ValueWithLabel label={<Header variant="h3">Your Status</Header>}>
                {personalStatus(riskInfo.personalRisk)}
            </ValueWithLabel>
            <Table
                columnDefinitions={[{
                    id: "user",
                    header: "User",
                    cell: item => item
                }]}
                header={<Header>Users in Violation ({riskInfo.inViolation.length})</Header>}
                items={riskInfo.inViolation.slice((currentPageIndex - 1) * 10, currentPageIndex * 10)}
                empty={<div>No users</div>}
                pagination={
                    <Pagination
                    pagesCount={Math.ceil(riskInfo.inViolation.length / 10)}
                    ariaLabels={{
                        nextPageLabel: "Next page",
                        previousPageLabel: "Previous page",
                        pageLabel: pageNumber =>
                        `Page ${pageNumber} of all pages`
                    }}
                    currentPageIndex={currentPageIndex}
                    onChange={({ detail }) => {
                        setCurrentPageIndex(detail.currentPageIndex, riskInfo);
                    }
                    }
                    />
                }
            />
        </SpaceBetween>
      </Modal>);
  
    return modal;
}

function convertMarkdownToHyperlink(text) {
    let linkInfo = text.match(/\[([^\]]+)\]\(([^\)]+)\)/)
    let content = []
    var lastString = text

    while (linkInfo != null) {
        let beforeLink = lastString.substring(lastString.indexOf(lastString), lastString.indexOf(linkInfo[0]))
        let afterLink = lastString.substring(lastString.indexOf(linkInfo[0]) + linkInfo[0].length);

        content.push(beforeLink, <a href={linkInfo[2]}>{linkInfo[1]}</a>)
        lastString = afterLink
        linkInfo = lastString.match(/\[([^\]]+)\]\(([^\)]+)\)/)
    }
    
    content.push(lastString)

	return content
}