
/*
    This module is intended as a wrapper around getting cookies.

    It creates an instance of `js-cookie` and make a custom "converter" (a custom function to read/write the value).
    The class exposes 2 static methods, `all` and `get`. All is mostly for debugging and returns all cookies in an
    object where the key is the cookie name. Get takes a string as the 'key' and attempts to return the cookie's value.

    The custom converter attempts to parse the value. If the parsing is successful, then it returns the value as an object.
    If the parsing fails, then it just returns the value as a string. 

    Works with 2 different types of cookies:
        - Where the name uses dot notation:
            example: CognitoIdentityServiceProvider.ka3611ksg7dh9mr9kp6vij82e.Midway_fflintstone.idToken
        - Where the name is just straight text (with no dotes):
            example: rememberme

    For keys using dot notation, the "key" used to search for is the value affixed to the end.
    For example, to find CognitoIdentityServiceProvider.ka3611ksg7dh9mr9kp6vij82e.Midway_fflintstone.idToken, the key would be idToken

    If the get method cannot find the key, it will throw an Error.

    Usage:
    ```
        import { Cookie } from 'Core/storage'

        const id = Cookie.get('idToken')
        console.log(id)
        // Prints the value of the idToken cookie

        const user = Cookie.get('UserInfo')
        console.log(user)
        // Prints a JavaScript object representing the User
    ```
*/
import Cookies from 'js-cookie';

const cookieJar = Cookies.withConverter({
    read: function (value, name) {
        try {
            return JSON.parse(value);
        } catch (e) {
            return value
        }
    }
})

export default class Cookie {
    static all() { return cookieJar.get()}
    static get(key) {
        const cookies = cookieJar.get()

        for (const [name,value] of Object.entries(cookies)){
            if(name.split('.').pop() == key) {
                return value
            }
        }
        throw Error(`Unable to find the ${key} cookie`)
    }
}