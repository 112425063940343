import {User} from "../core/user";

const INTERNAL_USER_PREFIX = "Midway_"
const TEST_INTERNAL_USERS = ["GetTestUser", "PutTestUser", "Superuser", "Employee", "Manager"]

export function isInternalUser() {
    let user = new User()
    if (user.cognito_username == null) {
        return false
    }
    return user.cognito_username.startsWith(INTERNAL_USER_PREFIX) || TEST_INTERNAL_USERS.indexOf(user.name) != -1
}