
// Get the UserDetails associated with the current user. This function expects 
// an axios client and the authenticated user object returned from cognito
export default async function getCurrentUserDetails(client, username, url = '/userdetails') {
    try {
        let userDetails = await client.get(url, undefined, { username });
        return userDetails;
    }
    catch (err) {
        console.warn(err);
        throw err;
    }
}
